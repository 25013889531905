import { Checkbox, Icon, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { memo } from "react";
import { useDrag } from "react-dnd";

export const DataFieldTypes = {
    DIMENSION: 'DIMENSION',
    MEASURE: 'MEASURE',
}

const DataField = memo(({ type, name, title, dataType, decimalPoints, isDropped, onSelection, hideTotal }) => {
    const [, dragRef] = useDrag({
        item: { type, name, title, dataType, decimalPoints, hideTotal },
        collect: (monitor) => ({
            opacity: monitor.isDragging() ? 0.4 : 1,
        }),
    },
        [name, title, type, dataType, decimalPoints, hideTotal],
    )

    let icon = "numbers";
    if (type === DataFieldTypes.DIMENSION) {
        switch (dataType) {
            case "number":
                icon = "numbers"
                break;
            case "currency":
                icon = "numbers"
                break;                
            // case "date":
            //     icon = "calendar_month"
            //     break;
            case "time":
                icon = "calendar_month"
                break;
            default:
                icon = "abc"
                break;
        }
    }

    return (
        <ListItem key={name} ref={dragRef}>
            <ListItemIcon>
                <Icon className="dragIcon">drag_indicator</Icon>
            </ListItemIcon>
            <ListItemText
                primary={
                    <MDBox display="flex" alignItems="center">
                        <Icon>{icon}</Icon>
                        <MDTypography data-testid = {title?.toLowerCase().replaceAll(' ', '')} ml={.5} variant="caption">{title}</MDTypography>
                        <Checkbox
                            checked={isDropped}
                            onChange={(_, checked) => {
                                onSelection({ type, name, title, dataType, decimalPoints, hideTotal }, checked);
                            }}
                            inputProps={{ "aria-label": `${title}` }}
                            sx={{
                                ml: "auto",
                                mr: 1,
                                p: 0,
                                "& .MuiSvgIcon-root": {
                                    height: 16,
                                    width: 16,
                                    border: "1px solid #c5c9cc",
                                    borderRadius: "4px"
                                }
                            }}
                        />
                    </MDBox>
                }
            />
        </ListItem>
    )
});

export default DataField;