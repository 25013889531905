import { CircularProgress, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getDomain } from "utils";
import MDButton from "components/MDButton";
import Dropdown from "components/YAForm/components/Dropdown";
import MultiSelect from "./components/MultiSelect";
import Textbox from "components/YAForm/components/Textbox";
import Integer from "components/YAForm/components/Integer";
import DynamicMultiSelect from "./components/DynamicMultiSelect";
import Axios from "axios";
import Switch from "./components/Switch";
import YASkeleton from "components/YASkeleton";
import { useYADialog } from "components/YADialog";
import DatePicker from "./components/DatePicker";
import fetchRequest from "utils/fetchRequest";
import Float from "./components/Float";
import moment from "moment";
import DynamicDropdown from "./components/DynamicDropdown";
const YAForm = (props) => {
    const { dialog: { mode, pkId, definition, submitRoute, fetchRoute }, onClose } = props;    
    const formId = definition.name;
    const formFields = (definition.fields || []).filter(field => !field.hideInForm);
    const [formDataSet, setFormDataSet] = useState([])
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState(null)
    const [err, setErr] = useState({})
    const [options, setOptions] = useState([])
    // const [relatedFields, setRelatedFields] = useState([])
    // const [defautValues, setDefautValues] = useState(buildDefaultValues(definition));
    const domain = getDomain();
    const { showSnackbar, showAlert } = useYADialog();
    const { watch, control, setValue, setError, formState: { errors, isSubmitting }, handleSubmit } = useForm({
        // defaultValues: defautValues
    });

    async function getFormData() {
        setLoading(true);
        const response = await Axios.get(fetchRoute ? fetchRoute+`?${("nc=" + Math.random()).replace(".", "")}` : `${domain}/api/master/${formId}/edit/${pkId}?${("nc=" + Math.random()).replace(".", "")}`);
        // setDefautValues(response.data);
        if (Array.isArray(formFields) && formFields.length > 0) {
            // let relatedFieldSet = []
            formFields.forEach((f) => {
                var val = response.data[f.name];
                if (f.type === "switch")
                    val = val || false;
                if (f.type === "datepicker" && val)
                    val = moment(val).format('YYYY-MM-DD');
                    f.schemaName === 'roles' && val === 'disabled' ? val = null : val
                setValue(f.name, val);
                if (f.type === 'multiselect') {
                    setValues(val)
                }
                // if (f.parentField) {
                //     let relatedField = { "fieldName": f.name, 'parentField': f.parentField, 'type': f.type, 'mode': mode }
                //     relatedFieldSet.push(relatedField)
                // }
            });
            // setRelatedFields(relatedFieldSet)
        }
        setLoading(false);
    }

    useEffect(() => {
        if (mode === "edit") {
            getFormData();
        }
        else {
            if (Array.isArray(formFields) && formFields.length > 0) {
                // let relatedFieldSet = []
                formFields.forEach((f) => {
                    var defaultValue = null;
                    if (f.type === "switch")
                        defaultValue = f.defaultValue || false;
                    setValue(f.name, defaultValue);
                    // if (f.parentField) {
                    //     let relatedField = { "fieldName": f.name, 'parentField': f.parentField, 'type': f.type }
                    //     relatedFieldSet.push(relatedField)
                    // }

                });
                // setRelatedFields(relatedFieldSet)
            }
        }
    }, [mode]);

    let handleAlert = (e, reverseSet) => {
        if (reverseSet && !e) {
            showAlert("Alert", "This action will result in the revocation of user access to the application");
        }
    }

    const onSubmit = async formData => {
        const url = mode === "edit" ? (submitRoute ? submitRoute : `${domain}/api/master/${formId}/${pkId}`) : (submitRoute ? submitRoute : `${domain}/api/master/${formId}/new`);
        definition.slectedfield && definition.slectedvalue ? formData[definition.slectedfield] = definition.slectedvalue : formData
        const [error, data] = await fetchRequest.post(url, JSON.stringify(formData));
        if (error) {
            if (error.data.message === "AdminRoleChangeFail") {
                showAlert("Attention", "Assign another admin before changing this user's role.")
            }
            else if (error.data.message === "DuplicateEntries") {
                definition.duplicateEntryMsg ? showAlert("Attention", definition.duplicateEntryMsg) : showAlert("Attention", "The entry already exists please select another one.");
            }
            else
            showAlert("Error", "Something went wrong. Contact your administrator.");
        }
        else {
            if (data && data.result === false) {
                if (Array.isArray(data.errors) && data.errors.length > 0) {
                    data.errors.forEach((e) => {
                        let errorfield = formFields.find(elem=>elem.name === e.field)
                        if(errorfield.type === 'multiselect')
                        {
                            setErr( { type: "manual", message: e.message })
                        }else
                        setError(e.field, { type: "manual", message: e.message });
                    });
                }
            }
            else {
                handleClose();
                if(formId){
                    if(formId === 'solution-offerings' || formId === 'business-units'){
                        let cubeVal = formId === "solution-offerings" ? "solutions" : "businessUnits"
                        var [err, refresh] = await fetchRequest.post(`/api/dataflow/cubeRefreshKey/${cubeVal}`);
                        if (err) {
                            console.err(err)
                        }
                        else {
                            showSnackbar(refresh, "success")
                        }
                    }
                    else if(formId === 'account' || formId === 'cost-center' || formId === 'vendor' ) {
                        let cubeVal = "all"
                        let [err1, refresh1] = await fetchRequest.post(`/api/dataflow/cubeRefreshKey/${cubeVal}`);
                        if (err) {
                            console.err(err1)
                        }
                        else {
                            showSnackbar(refresh1, "success")
                        }
                    }
                }
                showSnackbar(data.message, "success");
            }
        }
    };


    const handleClose = useCallback(() => {
        if (onClose) onClose();
    }, []);

    const renderContent = () => {
        if (loading) {
            return (
                <MDBox pt={1} px={2} minWidth={600}>
                    {
                        formFields.map((f) => (
                            <MDBox key={f.name}>
                                <YASkeleton variant="filter-item" />
                            </MDBox>)
                        )
                    }
                </MDBox>
            )
        }

        const hanleFormData = (name, value) => {
            let formData = formDataSet
            setFormDataSet(null)
            let updateOrAddObjectByKey = (array, key, newValue) => {
                const index = array.findIndex(obj => key in obj);
                if (index !== -1) {
                    array[index][key] = newValue;
                } else {
                    array.push({ [key]: newValue });
                }
                return array;
            }
            let newFormset = updateOrAddObjectByKey(formData, name, value);
            setFormDataSet(newFormset)
            // relatedFields.forEach(item => {
            //     if (item.type === "multiselect" && item.mode !== 'edit') {
            //         const newOptions = optionsCreate(item, options, newFormset)
            //         if (newOptions)
            //             setValues(newOptions)

            //     }
            // })
        }

        let optionSet = (name, option) => {
            let newOptions = options
            const index = newOptions.findIndex(obj => obj.name === name);
            if (index !== -1) {
                newOptions[index].options = option
            } else {
                newOptions.push({ "name": name, options: option })
                setOptions(newOptions)
            }
        }

        return (
            <MDBox pt={1} px={2} minWidth={600} overflow="auto">

                <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
                    <MDBox role="form">
                        {
                            formFields.map((f) => {
                                if (f.type === "multiselect") {
                                    return <MDBox mb={3} key={f.name}>
                                        <MultiSelect watch={watch} values={values} setValue={setValue} setError={setErr} control={control} formId={formId} fieldDef={f} errorMessage={err.message} optionSet={optionSet} />
                                    </MDBox>
                                }
                                if (f.type === "dynamicMultiSelect") {
                                    return <MDBox mb={3} key={f.name}>
                                        <DynamicMultiSelect watch={watch} setValue={setValue} control={control} formId={formId} fieldDef={f} errorMessage={errors[f.name] && errors[f.name].message} mode={mode} optionSet={optionSet} />
                                    </MDBox>
                                }
                                else if (f.type === "dropdown") {
                                    return <MDBox mb={3} key={f.name}>
                                        <Dropdown watch={watch} setValue={setValue} control={control} formId={formId} fieldDef={f} errorMessage={errors[f.name] && errors[f.name].message} mode={mode} hanleFormData={hanleFormData} formDataSet={formDataSet} optionSet={optionSet} />
                                    </MDBox>
                                }
                                else if (f.type === "dynamicDropdown") {
                                    return <MDBox mb={3} key={f.name}>
                                        <Dropdown watch={watch} setValue={setValue} control={control} formId={formId} fieldDef={f} errorMessage={errors[f.name] && errors[f.name].message} />
                                    </MDBox>
                                }
                                else if (f.type === "dynamicDropdown2") {
                                    return <MDBox mb={3} key={f.name}>
                                        <DynamicDropdown watch={watch} setValue={setValue} control={control} formId={formId} fieldDef={f} errorMessage={errors[f.name] && errors[f.name].message} />
                                    </MDBox>
                                }
                                else if (f.type === "textbox" || f.type === "string") {
                                    return <MDBox mb={3} key={f.name}>
                                        <Textbox watch={watch} setValue={setValue} control={control} formId={formId} fieldDef={f} errorMessage={errors[f.name] && errors[f.name].message} passwordEnable={f.password ? f.password : false} />
                                    </MDBox>
                                }
                                else if (f.type === "datepicker") {
                                    return <MDBox mb={3} key={f.name}>
                                        <DatePicker watch={watch} setValue={setValue} view={f.view ? f.view : ""} control={control} formId={formId} fieldDef={f} errorMessage={errors[f.name] && errors[f.name].message} />
                                    </MDBox>
                                }
                                else if (f.type === "integer" || f.type === 'number') {
                                    return <MDBox mb={3} key={f.name}>
                                        <Integer watch={watch} setValue={setValue} control={control} formId={formId} fieldDef={f} errorMessage={errors[f.name] && errors[f.name].message} />
                                    </MDBox>
                                }
                                else if (f.type === "float") {
                                    return <MDBox mb={3} key={f.name}>
                                        <Float watch={watch} setValue={setValue} control={control} formId={formId} fieldDef={f} errorMessage={errors[f.name] && errors[f.name].message} />
                                    </MDBox>
                                }
                                else if (f.type === "currency") {
                                    return <MDBox mb={3} key={f.name}>
                                        <Float watch={watch} setValue={setValue} control={control} formId={formId} fieldDef={f} errorMessage={errors[f.name] && errors[f.name].message} />
                                    </MDBox>
                                }
                                else if (f.type === "switch") {
                                    return <MDBox mb={3} key={f.name}>
                                        <Switch watch={watch} setValue={setValue} control={control} formId={formId} fieldDef={f} errorMessage={errors[f.name] && errors[f.name].message} handleAlert={handleAlert}  />
                                    </MDBox>
                                }
                                else {
                                    return <MDBox mb={3} key={f.name}>
                                        unknown field type
                                    </MDBox>
                                }
                            })
                        }
                    </MDBox>
                    <MDBox mt={4} mb={1} textAlign="right">
                        <MDButton type="submit" variant="gradient" color="info" disabled={isSubmitting} startIcon={isSubmitting ? <CircularProgress color="white" size={15} /> : <Icon>save</Icon>}>
                            Save
                        </MDButton>
                    </MDBox>
                </form>
            </MDBox>
        );
    };

    return renderContent();
};

export default YAForm;