import MDBox from "components/MDBox";
import useHandleError from "hooks/useHandleError";
import { useState, useEffect } from "react";
import { useImmer } from "use-immer";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import fetchRequest from "utils/fetchRequest";
import { Icon, Card, IconButton, Autocomplete, Checkbox, Chip, Tooltip, CircularProgress   } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DataTable from "components/DataTable";
import MDTypography from "components/MDTypography";
import { useYADialog } from "components/YADialog";
import Axios from "axios";
import { getDomain } from 'utils';
import EmptyState from "components/EmptyState";
import new_item_img from 'assets/svg/add_new.svg';

const MetricsAllocate = (props) => {
    const [lookUpMetrics, setLookUpMetrics] = useState();
    const [metrics, setMetrics] = useState()
    const [ metricTabRefresh, setmetricTabRefresh ] = useState(null)
    const [contractMetricRow, setContractMetricRow] = useImmer([]);
    const handleError = useHandleError();
    const { contractId } = props;
    const {  showAlert, showPrompt, showSnackbar } = useYADialog();
    const [buttonStatus, setbuttonStatus] =  useState("Add")
    const domain = getDomain();

    const deleteMetricItem = async (pkId) => {
        const response = await Axios.delete(`${domain}/api/metric/contractMetrics/${pkId}`);
        if (response.data && response.data.result === true) {
            showSnackbar(response.data?.message, "success");
            setmetricTabRefresh(Math.random())
            // handleClose();

        }
        else {
            showAlert("Delete", "Something went wrong. Contact your administrator.");
        }

    }


    const handleDeleteSuccess = (pkId, type) => {
        if (type && type === 'metric') {
            deleteMetricItem(pkId);
        } else {
            // deleteBusinessCaseItem(pkId);
        }
    }
    const handleDelete = (pkId, type) => {
        if (type && type === 'metric') {
            showPrompt("Delete", "Are you sure you want to delete?", () => { 
                    handleDeleteSuccess(pkId, type);
            });
        } else {
            showPrompt("Delete", "Are you sure you want to delete?", () => {
                    handleDeleteSuccess(pkId);
            });
        }
    }

    const handleAddMetricButtonClick = async () => {
        if (metrics.length > 0 ) {
            setbuttonStatus("Progress")
            const filterLookup = lookUpMetrics.filter((item) => !metrics?.some((itm)=>  itm.companionMetricLookupId == item.companionMetricLookupId))
            setLookUpMetrics(filterLookup)
            var [err, ] = await fetchRequest.post(`/api/metric/contractMetrics/${contractId}`, metrics);
            if (err) {
                handleError(err);
            }
            try {
                // let resultData = await contractMetricRows(r, data, handleDelete)
                // setContractMetricRow(resultData);
                setmetricTabRefresh(Math.random())
                setbuttonStatus("Add")
            } catch (error) {
                console.error('Error while fetching data', error);
                setbuttonStatus("Add")
            }
        }
        setMetrics([])
    }

    const contractMetricRows = async ( data, onDeleteMetric) => {
        const rows = [];
        data.forEach((r) => {
            let row = {}
            Object.keys(r).forEach((k) => {
                row[k.replace(/\./g, "__")] = r[k]
            })
            row['actions'] = (
                <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }}>

                        <Tooltip title="Delete" placement="top">
                            <IconButton sx={{ padding: 0, paddingRight: 1 }} onClick={() => onDeleteMetric(row["id"], 'metric')}>
                                <Icon fontSize="small" color="error">delete</Icon>
                            </IconButton>
                        </Tooltip>
                </MDBox>
            )
            rows.push(row)
        })
        return rows;
    }
    

    useEffect(() => {
        const getMetricsList = async () => {
            if(contractId)
            {
                var [err, metricData] = await fetchRequest.get(`/api/metric/lookupMetric/contract/${contractId}`)
                if (err) {
                    handleError(err);
                }
                else {
                    setLookUpMetrics(metricData)
                }

            }
        }
        getMetricsList();
        const getContractMetricList = async () => {

            var [err, contractMetricData] = await fetchRequest.get(`/api/metric/contractMetrics/${contractId}`)
            if (err) {
                handleError(err);
            }
            else {
                let resultData = await contractMetricRows(contractMetricData, handleDelete)
                setContractMetricRow(resultData);
            }
        }
        getContractMetricList()
    }, [metricTabRefresh])


    const renderTags = (value, getTagProps) => {
        const limt = value.slice(0,1)
        return (
            <>
             <MDBox  sx={{
               display: 'flex',
               justifyContent: 'space-between',
               alignItems: 'center',
  }}>
            {limt.map((opt, idx)=> (
                <Chip
                color = "secondary"
                variant = "filled"
                key= {idx}
                label = {opt.name}
                {...getTagProps({ idx })}/>
            ))}
            {value.length > 1 && <Chip sx = {{alignItems : 'right'}}label={`+${value.length - 1} more`} />}
            </MDBox>
            </>
        )
    }

    const defaultContactCompanionMetricColumn = [
        { Header: "Actions", accessor: "actions", 'align': 'left', "disableSorting": true, maxWidth: 30 },
        { Header: "Name", accessor: "companionMetricLookup__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{(value)}</MDTypography> } },
        { Header: "Target", accessor: "companionMetricLookup__target", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{(value)}</MDTypography> } },
        { Header: "Units", accessor: "companionMetricLookup__units", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    ];

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;


    return (<>
             <MDBox px={2}  pb={2} overflow="auto">
            <MDBox p={-1} pt={1} style={{ display: 'flex', alignItems: 'center' }}>
                <Autocomplete
                    id="contract-metrics"
                    multiple
                    className="customAutocomplete"
                    options={lookUpMetrics || []}
                    disableClearable={false}
                    disableCloseOnSelect
                    noOptionsText="No metrics available"
                    value={metrics || []}
                    autoHighlight
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) => option.name === value.name}
                    renderOption={(props, options, { selected }) => (
                        <li {...props}>
                            <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: '8px' }} checked={selected} />
                            {options.name}
                        </li>
                    )}
                    sx={{
                        ml: 1.5,
                        // justifyContent: 'space-between',
                        "& .MuiOutlinedInput-root": {
                            height: 48,
                            width: 330,
                            boxShadow: "0 8px 16px #1a488e1f",
                        },
                        "& .MuiOutlinedInput-input": {
                            fontSize: 14,
                            //   display: "inline-block !important",
                            //   display: "flex",
                            //   justifyContent: "space-between"

                        },

                        "& .MuiAutocomplete-tag": {
                            display: "inline-block !important",
                            backgroundColor: "rgba(0, 0, 0, 0.08)",
                            color: "rgba(0, 0, 0, 0.87)",
                            maxWidth: "150px",
                            overflow: "hidden",
                            textOverflow: "ellipsis"

                        },
                        '& .MuiAutocomplete-tag .MuiSvgIcon-root, & .MuiAutocomplete-tag .MuiSvgIcon-root:hover, & .MuiAutocomplete-tag .MuiSvgIcon-root:focus': {
                            color: '#003',
                            visibility: 'hidden'
                        }
                    }}
                    renderInput={(params) => <MDInput sx={{ display: 'flex', justifyContent: 'space-between' }} label="Metrics" {...params} />}
                    onChange={(event, newValue) => {
                        setMetrics(newValue);
                    }}
                    renderTags={renderTags}
                />
                {
                    <MDButton sx={{ m: 2 }} variant="gradient" color="info" startIcon={buttonStatus !== "Add" ? <CircularProgress color="info" size={20} /> : <Icon>add</Icon>} disabled={(!lookUpMetrics?.length || !metrics?.length || buttonStatus !== "Add") ? true : false} onClick={handleAddMetricButtonClick}>
                        Add
                    </MDButton>
                }
            </MDBox>
            {contractMetricRow?.length > 0 && <MDBox>
                <Card sx={{ height: "100%", width: "100%", mt: 1 }} px={5}>
                    <DataTable
                        table={{ columns: defaultContactCompanionMetricColumn, rows: contractMetricRow }}
                        isSorted={true}
                        entriesPerPage={true}
                        canSearch={true}
                    // onDelete= {}
                    />
                </Card>
            </MDBox>}
            {contractMetricRow?.length === 0 &&
                  <EmptyState
                  size="large"
                  image={new_item_img}
                  title={`No Metrices Added Yet`}
                  description={
                     `Select metrics from the dropdown and click the '+ Add' button to add them to the contract."`
                  }
              />
            }
        </MDBox>
    </>)
}

export default MetricsAllocate;