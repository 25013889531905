import { forwardRef, useCallback, useState, cloneElement } from "react";
import { useSnackbar } from "notistack";
import IconButton from "@mui/material/IconButton";
import MDBox from "components/MDBox";
import { CircularProgress, Icon, ListItem, ListItemAvatar, ListItemText, Tooltip } from "@mui/material";
import { NotificationStatus } from "constants";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useNotificationSubscription } from "hooks/useNotificationManager";
import MDTypography from "components/MDTypography";
import { getTargetRouteForNotification, getDetailsPopupForNotification } from "utils/notification";

const snackbarStyles = ({ typography: { size }, boxShadows }, { snackbar }) => ({
    minWidth: 300,
    maxWidth: 400,
    background: "white",
    display: "flex",
    flexDirectoion: "column",
    py: 1.25,
    pl: 2,
    pr: 1,
    ...(!snackbar && { mb: 1 }),
    ...(snackbar && { boxShadow: boxShadows.sm }),
    border: "1px solid #dddddd",
    borderRadius: "6px",
    cursor: "pointer",
    "& .MuiListItemText-primary": {
        fontWeight: 500,
        fontSize: size.sm,
        lineHeight: size.md,
        whiteSpace: "pre-wrap"
    },
    "& .notification-timestamp": {
        ml: 1,
        fontSize: size.xs,
        lineHeight: size.sm,
        whiteSpace: "nowrap",
        color: "rgba(0, 0, 0, 0.5)",
    },
    "& .MuiListItemText-secondary": {
        fontSize: size.sm,
        lineHeight: size.sm,
        whiteSpace: "pre-wrap",
        mt: .75
    },
    "& .MuiListItemAvatar-root": {
        minWidth: "inherit",
        mr: .5
    },
    "& .statusIcon": {
        height: 26,
        width: 26,
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        mr: 1.25
    },
    "& .statusIcon .MuiIcon-root": {
        color: "white!important"
    }
});

export const NotificationMessageCard = ({ cardRef, snackbar, id, onDismiss, onDrawerClose, ...props }) => {

    const navigate = useNavigate();
    const [popupContent, setPopupContent] = useState(null);
    const [triggerOpen, setTriggerOpen] = useState(false);

    const { type, message, description, status, date, ...options } = props;

    const { details } = options;

    let statusIcon = "info";
    let statusColor = "info";

    if (status === NotificationStatus.Success) {
        statusIcon = "done";
        statusColor = "success";
    }
    else if (status === NotificationStatus.Error) {
        statusIcon = "error_outlined";
        statusColor = "error";
    }
    else if (status === NotificationStatus.Partial) {
        statusIcon = "rule_outlined";
        statusColor = "warning";

    }
    else if (status === NotificationStatus.Processing) {
        statusColor = "white";
    }


    const handleOnNotificationClick = () => {
        const targetRoute = getTargetRouteForNotification(type, { ...options });
        if (targetRoute)
            navigate(targetRoute);
        if (onDrawerClose)
            onDrawerClose();
    };

    const handleOnViewDetailsClick = () => {
        const popup = getDetailsPopupForNotification(type, { ...options });
        if (popup)
            setPopupContent(cloneElement(popup, {
                triggerOpen: true,
                onClose: () => setTriggerOpen(false),
            }));
        setTriggerOpen(true);
    };

    const renderStatusIcon = () => {
        if (status === NotificationStatus.Processing)
            return <CircularProgress size={24} color="info" />
        return <Icon>{statusIcon}</Icon>
    };

    const renderMessageCard = () => {
        if (snackbar)
            return <>
                <ListItem component="div" onClick={!(details?.onclickPopup) && handleOnNotificationClick}>
                    <ListItemAvatar>
                        <MDBox bgColor={statusColor} className="statusIcon">{renderStatusIcon()}</MDBox>
                    </ListItemAvatar>
                    <ListItemText primary={message}
                        secondary={
                            <MDBox color={statusColor} onClick={() => handleOnViewDetailsClick()} sx={{ '&:hover': { textDecoration: 'underline' } }} >{(status === NotificationStatus.Error || status === NotificationStatus.Partial) && details?.onclickPopup && "View Details"}</MDBox>
                        }
                    />
                </ListItem>
                <div className={"icons"}>
                    <Tooltip title="Close">
                        <IconButton
                            size="small"
                            onClick={() => onDismiss(id)}
                        >
                            <Icon>close</Icon>
                        </IconButton>
                    </Tooltip>
                </div>
            </>

        return <>
            <ListItem component="div" onClick={!(details?.onclickPopup) && handleOnNotificationClick}>
                <ListItemAvatar>
                    <MDBox bgColor={statusColor} className="statusIcon">{renderStatusIcon()}</MDBox>
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <>
                            {message}
                            <MDTypography component="span" className="notification-timestamp">{moment(date).fromNow()}</MDTypography>
                        </>
                    }
                    secondary={details?.onclickPopup ?
                        <MDBox color={statusColor} onClick={() => handleOnViewDetailsClick()} sx={{ '&:hover': { textDecoration: 'underline' } }} >{(status === NotificationStatus.Error || status === NotificationStatus.Partial) && "View Details"}</MDBox>
                        : description
                    }
                />
            </ListItem>
            <div className={"icons"}>
                <Tooltip title="Mark as read">
                    <IconButton
                        size="small"
                        onClick={() => onDismiss(id)}
                    >
                        <Icon>visibility</Icon>
                    </IconButton>
                </Tooltip>
            </div>
        </>
    };

    return (
        <>
            <MDBox ref={cardRef} sx={(theme) => snackbarStyles(theme, { snackbar })}>
                {renderMessageCard()}
            </MDBox>
            {triggerOpen && popupContent}
        </>
    );
};

const NotificationMessage = forwardRef(
    ({ id, type, ...props }, ref) => {
        const { closeSnackbar } = useSnackbar();
        const [notificationDetails, setNotificationDetails] = useState({ type, ...props });

        useNotificationSubscription(type, (n) => {
            if (n.requestId === notificationDetails.requestId) {
                setNotificationDetails(n);
            }
        });

        const handleDismiss = useCallback((id) => {
            closeSnackbar(id);
        }, [id, closeSnackbar]);

        return <NotificationMessageCard cardRef={ref} snackbar onDismiss={handleDismiss} {...notificationDetails} />;
    }
);

export default NotificationMessage;
