import React, { useCallback, useContext, useMemo } from 'react'
import { useImmer } from 'use-immer';
import { generateUUID } from 'utils';

export const NotificationsContext = React.createContext()

export function NotificationsProvider({ children }) {

    const [notifications, setNotifications] = useImmer([]);

    const addNotification = useCallback((notification) => {
        let notificationFound = false;
        if (notification.requestId) {
            setNotifications(draft => {
                let requestedNotification = draft.find(n => n.requestId === notification.requestId);
                if (requestedNotification) {
                    notificationFound = true;
                    requestedNotification.status = notification.status;
                    requestedNotification.message = notification.message;
                    requestedNotification.description = notification.description;
                    requestedNotification.details = notification?.details
                }
            })
        }
        if (!notificationFound) {
            const updatedNotification = { ...notification, id: generateUUID(), date: new Date() }
            setNotifications(draft => draft.concat(updatedNotification))
        }
        return notificationFound;
    }, []);

    const removeNotification = useCallback((id) => {
        setNotifications(draft => draft.filter(n => n.id !== id))
    }, []);

    const findNotification = useCallback((requestId) => {
        console.log('findNotification', JSON.stringify(notifications));
        return notifications.find(n => n.requestId === requestId);
    }, [notifications]);

    const notificationsCount = useMemo(() => (notifications || []).length, [notifications]);

    const contextValue = useMemo(() => ({ notifications, notificationsCount, addNotification, removeNotification, findNotification }), [notifications, notificationsCount, addNotification, removeNotification, findNotification]);

    return (
        <NotificationsContext.Provider value={contextValue}>
            {children}
        </NotificationsContext.Provider>
    )
}

export const useNotificationsContext = () => {
    return useContext(NotificationsContext);
};