import { NotificationType } from "constants";
import { ErrPopup } from "./notificationRepo";

export const getTargetRouteForNotification = (notificationType, { details }) => {
    if (notificationType === NotificationType.Alert && details.alertId) {
        return `/alerting/${details.alertId}/alert-details`;
    }
    else if (notificationType === NotificationType.Interface) {
        return `/admin/integrations`;
    }
    return null;
};

export const getDetailsPopupForNotification = (notificationType, { details }) => {
    if (notificationType === NotificationType.ProcessFile && details.fileId) {
            return <ErrPopup fileId={details.fileId} triggerOpen={false}  />
}
    return null;
}