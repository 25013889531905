import { useEffect } from 'react';
import fetchRequest from 'utils/fetchRequest';

const RefreshToken = () => {
    // Function to call the refresh token API
    const refreshToken = async () => {
        try {

            let [err, data] = await fetchRequest.post(`/auth/refresh`)
            if (err) {
                console.error(err)
            }
            else if (data) {
                console.log('Token refreshed successfully', data);
            }

        } catch (error) {
            console.error('Error refreshing token:', error);
        }
    };

    useEffect(() => {
        refreshToken();

        // Set up interval to refreshToken every 5 minutes
        const intervalId = setInterval(refreshToken, 300000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return null; // This component doesn't render anything
};

export default RefreshToken;