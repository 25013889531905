import { Icon, IconButton, Tooltip, CircularProgress } from "@mui/material";
import _ from "lodash";
import MDTypography from "components/MDTypography";
import DataTable from "components/DataTable";
import YASkeleton from "components/YASkeleton";
import { useState, useRef } from "react";
import fetchRequest from "utils/fetchRequest";
import { useYADialog } from "components/YADialog";
import DataloadErrorsDialog from "components/DataloadErrorsDialog";
import moment from "moment";
import MDAvatar from "components/MDAvatar";
import Axios from "axios";
import MDBox from "components/MDBox";
import { useEffect } from "react";
import { useImmer } from "use-immer";
import { DataStoreInterfaceHandler } from "./helpers/DataStoreInterfaceHandler";
import { useSearchParams } from "react-router-dom";
import useFetchRequest from "hooks/useFetchRequest";
import DataloadMfDialog from "components/DataloadMfDialog";

const FilteredUploadedFiles = (props) => {
    const [searchParams,setSearchParams ] = useSearchParams();
    const timer = useRef()
    const fileId = searchParams.get("fileId");
    const { showAlert, showPrompt, showSnackbar, showCustomForm } = useYADialog();
    const { yearFilter, monthFilter, canFilter, fileType, originalFileName, containerHeight, fileName, monthFilterName, yearFilterName, reloadDataspnd, chargeBackMonthly, condition, setRefresh } = props;
    const [openErr, setOpenErr] = useState(false);
    const [hideYear, setHideYear] = useState(false);
    const [hideMonth, setHideMonth] = useState(false);
    const [hideChargeback, setHideChargeback] = useState(false)
    const [errors, setErrors] = useState([]);
    const [ufData, setUFData] = useState({});
    const [ufDef, setUFDef] = useState({});
    const [openMf, setOpenMf] = useState(false)
    const [interfaceList, setInterfaceList] = useState([])
    const [publishMonthly, setPublishMonthly] = useState([])
    const [rows, setRows] = useState(null)
    const [_err, setError] = useState(null)
    const [ loading, setLoading ] = useState(false)
    const [ tableRefresh, setTableRefresh ] = useState(null)
    const [reloadId, setReloadId] = useState(null);
    const { response: lookUpList, error: err, loading: listLoading, reloadData } = yearFilter ? useFetchRequest(`/api/dataflow/uploadFileslookup?year=${yearFilter}`) : useFetchRequest(`/api/dataflow/uploadFileslookup`)
    let dataStoreExcludes = ["BUConsumption", "businessCaseCloud", "businessCaseNonCloud", "budgetDetail", "costCentreBudgetDetail", "Business Unit Consumption"]
    let isdataStoreExclude = (destTable) => {
        return dataStoreExcludes.find(elem => elem === destTable)
    }
    let filters = []
    let fil = {
        id: 'destinationTable',
        value: {
            type: 'textbox',
            operator: 'eq',
            session: false,
            values: [fileName].flat()
        }

    }
    let filType = {
        id: 'fileType',
        value: {
            type: 'textbox',
            operator: 'eq',
            session: false,
            values: [fileType].flat()
        }

    }
    let orgFileName = {
        id: 'originalFileName',
        value: {
            type: 'textbox',
            operator: 'eq',
            session: false,
            values: [originalFileName]
        }

    }
    let monthFil = {
        id: 'monthName__name',
        value: {
            type: 'textbox',
            operator: 'eq',
            session: false,
            values: [monthFilterName]
        }

    }

    fileName ? filters.push(fil) : ""
    originalFileName ? filters.push(orgFileName) : ""
    fileType ? filters.push(filType) : ""
    monthFilterName ? filters.push(monthFil) : ""

    if (lookUpList && !err) {
        filters.map( filter => {
            let availableValues = [...new Set(lookUpList[filter.id])]                    
            let updatedValues = filter.value.values.filter( val => availableValues.includes(val))
            if (filter.value.values.length > 1 && updatedValues.length > 0)
                filter.value.values = updatedValues
            if (updatedValues.length == 0)
                filter.value.values = [filter.value.values[0]]
            return filter
        });   
    }

    const [filtersState, setFiltersState] = useImmer({ globalFilter: undefined, filters: filters });

    const handleOnFiltersStateUpdate = (latestGlobalFilter, latestFilters) => {
        setFiltersState(draft => {
            draft.globalFilter = latestGlobalFilter;
            draft.filters = latestFilters;
        });
    }

    const getPublished = async() => {
        const [err1, data] = await fetchRequest.get('/api/dataflow/publish')
        if (!err1) {
            setPublishMonthly(data.filter(i => i != null)?.[0])
        }
    }

    const getInterfaceList = async () => {
        const [err1, data] = await fetchRequest.get('/api/interface/list')
        if (!err1) {
            setInterfaceList(data)
        }
    }

    useEffect(() => {
        if (fileType === 'Master') {
            setHideYear(true);
            setHideMonth(true);
            setHideChargeback(true)
        }
        if (yearFilter && yearFilter !== null) {
            setHideYear(false);
        }
        if (monthFilter && monthFilter !== null) {
            setHideMonth(false);
        }
        getInterfaceList()
        getPublished()
    },[tableRefresh])

    useEffect(() => {
        if (lookUpList && filtersState.filters.length > 0 && lookUpList) {
            setFiltersState(draft => {
                draft.filters = draft.filters.map( filter => {
                    let availableValues = [...new Set(lookUpList[filter.id])]                    
                    let updatedValues = filter.value.values.filter( val => availableValues.includes(val))
                    if (filter.value.values.length > 1 && updatedValues.length > 0)
                      filter.value.values = updatedValues
                    if (updatedValues.length == 0)
                      filter.value.values = [filter.value.values[0]]
                    return filter
                });
            });
        }
    },[lookUpList, tableRefresh])

    useEffect(async () => {
        let filterConditions = Object.assign({}, condition)
        if (filtersState) {
            filterConditions['filters'] = filtersState.filters??[]
            if (filterConditions) {
                setLoading(true)
                let [err, data] = yearFilter ? await fetchRequest.post(`/api/dataflow/uploadedFiles/${yearFilter}`, JSON.stringify(filterConditions)) : await fetchRequest.post(`/api/dataflow/uploadedFiles`, JSON.stringify(filterConditions))
                if (err) {
                    setError(err)
                    console.error('err', err)
                    setLoading(false)
                } else {
                    setRows(data);
                    setLoading(false)
                }
            }
        }
    },[filtersState, yearFilterName, monthFilterName, tableRefresh])

   
    useEffect(() => {
        if (fileId && rows) {
            const filteredRows = rows.filter(item => item.id == fileId);
            if (filteredRows.length !== rows.length) {
                setRows(filteredRows);
            }
            setSearchParams({})
        }
    }, [fileId, rows]);
      
     

    const handleErrDialogClose = () => {
        setOpenErr(false);
    }

    const handleDelete = (item) => {
        showPrompt("Delete", "Are you sure you want to delete - [" + item["originalFileName"] + "]", async () => {
            let [err1, data] = await fetchRequest.delete(`/api/dataflow/uploadedFile/${item["id"]}`)
            if (err1) {
                console.error(err1)
                showAlert("Delete", "Something went wrong. Contact your administrator.");
            }
            else if (data) {
                showSnackbar(data, "success")
                reloadData()
                setTableRefresh(Math.random())
                if (reloadDataspnd) {
                    reloadDataspnd()
                }
                setRefresh(Math.random())
            }
        })
    }


    const handleAutomate = async (item) => {
        const modelData = {};
        modelData["Name"] = item.destinationTable
        modelData["DestinationTable"] = item.orginalDestinationTable
        modelData["FileType"] = item.fileType
        modelData["MappingFields"] = item.mappingFields
        modelData["DefaultFields"] = "[]"
        await DataStoreInterfaceHandler(modelData, showAlert, showCustomForm, false, getInterfaceList)
    }

    const logMessage = async (msg) => {
        await fetchRequest.post(`/api/blob/logMessage`, { message: msg })
    }

    const retrieveGetURL = async (item) => {
        let [err1, data] = await fetchRequest.get(`/api/blob/presignedGet/${item["id"]}`)
        if (err1)
            showAlert(err1)
        if (data) {
            const response = await Axios.get(data, { responseType: "blob" });
            let blob = new Blob([response.data]);
            let url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.setAttribute("href", url);
            link.setAttribute("download", item.originalFileName);
            link.style = "visibility:hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            if(item.monthName__name && item.yearName__name){
                logMessage(`${item.destinationTable} downloaded sucessfully for ${item.monthName__name} ${item.yearName__name}`)
            }
            else{
                item.destinationTable ? logMessage(`${item.destinationTable} downloaded sucessfully`) : logMessage(`${item.fileType} downloaded sucessfully`)
            }
        }
    }
    const handleMfDialogClose = () => {
        setOpenMf(false)
    }

    const checkFileUploadStatus = async (fileId) => {
        const [error, data] = await fetchRequest.get(`/api/dataload/uploadstatus/${fileId}`);
        if (error) {
            console.error("An error occured while fetching upload status");
            console.error(error);
        }
        return data;
    }

    const handleReload = async (item) => {
        showPrompt("Reload", "All upload rules will reapply, which may result in changing the underlying data. Do you wish to proceed?", async () => {
            setReloadId(item.id)
            var uf = {}
            uf.originalFileName = item.originalFileName
            uf.fileName = item.fileName
            uf.mappingFields = item.mappingFields
            uf.yearNameId = item.yearNameId
            uf.monthNameId = item.monthNameId
            uf.totalRecords = item.totalRecords
            uf.destinationTable = item.orginalDestinationTable
            uf.uploadSubType = item.orginalDestinationTable

            const [resError, response] = await fetchRequest.post(`/api/dataload/createupload?reload=${true}`, uf);
            if (resError) {
                console.error("An error occured while reloading file");
                console.error(resError);
                return false;
            }

            const [relodError, reloadResponse] = await fetchRequest.post(`/api/dataload/uploaded`, { fileId: response.id, reload: true, originalFileId: item.id });
            if (relodError) {
                console.error("An error occured while updating file status");
                console.error(relodError);
                return false;
            }
            else if (reloadResponse?.result === false) {
                console.error("An error occured while updating file status");
                return false;
            }

            timer.current = setInterval(async () => {
                const uploadFile = await checkFileUploadStatus(response.id);
                const uploadStatus = uploadFile?.fileStatus.toLowerCase();
                if (["loaded", "error", "partial"].includes(uploadStatus)) {
                    clearInterval(timer.current);
                    showSnackbar("File reloaded successfully", "success");
                    reloadData();
                    setReloadId(null)
                    setRefresh(Math.random())
                }

            }, 2000)

        })
    }

    const viewErrors = async (item, def) => {
        if (item.errors) {
            setErrors(JSON.parse(item.errors.replace(/'/g, "")))
            setUFData(item);
            setUFDef(def);
            setOpenErr(true)
        }
    }
    const viewMapping = async (item, def) => {
        setUFDef(def);
        setUFData(item);
        setOpenMf(true)
    }

    let colorSet = (item) => {
        let color
        let sourceDataCheck = interfaceList.find(obj => obj.name === item.destinationTable)
        sourceDataCheck ? color = "info" : color = ""
        return color
    }


    rows?.map((item) => {
        item["createdByUser__name"] = item["createdByUser.name"];
        item["monthName__name"] = item["monthName.name"];
        item["yearName__name"] = item["yearName.name"];
        item["chargeBack"] = _.find(chargeBackMonthly , { monthNameId : item["monthNameId"], yearNameId: item["yearNameId"]}) ? true : false;
        item["publish"] = _.find(publishMonthly , { monthNameId : item["monthNameId"], yearNameId: item["yearNameId"] }) ? true : false;
    })
    // uploadedFiles?.filter(uf => uf.destinationTable === "Cloud Consumption").map((item) => {
    //     item["publish"] = _.find(publishMonthly , { monthNameId : item["monthNameId"], yearNameId: item["yearNameId"] }) ? true : false;
    // })

    let columns = [
        {
            Header: "Actions",
            accessor: "id",
            align: "left",
            disableSorting: true,
            disableFilters: true,
            Cell: ({ row }) => {
                return (
                    <MDBox display="flex" alignItems="left" mt={{ xs: 2, sm: 0 }}>
                      {row.values.fileType !== 'Masters' && (
                        <Tooltip title="Delete" placement="top">
                          <IconButton sx={{ padding: 0, paddingRight: 1.5 }} onClick={() => row.values.chargeBack || (row.original.publish && row.original.orginalDestinationTable === "cloudConsumption") ? null : handleDelete(row.values)}>
                            <Icon color={row.values.chargeBack || (row.original.publish && row.original.orginalDestinationTable === "cloudConsumption") ? 'disabled' : "error"} fontSize='small'>delete</Icon>
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip title="Download" placement="top">
                        <IconButton sx={{ padding: 0, paddingRight: 1.5  }} onClick={() => retrieveGetURL(row.values)} >
                          <Icon fontSize='small'>download</Icon>
                        </IconButton>
                      </Tooltip>
                      {(reloadId === row.original.id ?
                            <CircularProgress color="info" size={20} /> :
                            <Tooltip title="Reload" placement="top">
                                <IconButton sx={{ padding: 0.3, ":hover": { bgcolor: 'rgba(25, 118, 210, 0.2)' } }} disabled={(row.values.chargeBack || (row.original.publish && row.original.orginalDestinationTable === "cloudConsumption")) || row.original.orginalDestinationTable !== 'expenditure' || !row.original.reload } onClick={() => handleReload(row.original)} color="info">
                                    <Icon fontSize='small'>refresh</Icon>
                                </IconButton>
                            </Tooltip>
                        )}
                      {row.values.destinationTable && !isdataStoreExclude(row.values.destinationTable) && (
                        <Tooltip title="Automate" placement="top">
                          <IconButton sx={{ padding: 0, paddingRight: 1.5 }} onClick={() => handleAutomate(row.original)} color={colorSet(row.original)}>
                            <Icon fontSize='small'>bolt</Icon>
                          </IconButton>
                        </Tooltip>
                      )}
                    </MDBox>
                );
            }
        },
        { Header: "Year", accessor: "yearName__name", disableFilters: true, dataType: "textbox", hidden: hideYear, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Month", accessor: "monthName__name", dataType: "textbox", hidden: hideMonth, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "File Name", accessor: "originalFileName", dataType: "string", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Destination", accessor: "destinationTable", dataType: "textbox", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "File Type", accessor: "fileType", dataType: "textbox", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "File Status", accessor: "fileStatus", dataType: "textbox", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Loaded Records", accessor: "loadedRecords", align: "right", disableFilters: true, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Total Records", accessor: "totalRecords", align: "right", disableFilters: true, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Errors", accessor: "errors", disableFilters: true, Cell: ({ row }) => { return row.values.errors !== '[]' && row.values.errors !== '' && row.values.errors !== '{}' && row.values.errors !== null ? <IconButton sx={{ padding: 0, paddingLeft: '8px' }} onClick={() => viewErrors(row.values, row.original.def)}><Icon color="error">error</Icon></IconButton> : '' }, "type": "showonvalue" },
        { Header: "Mapping fields", accessor: "mappingFields", disableFilters: true, Cell: ({ row }) => { return <IconButton sx={{ padding: 0, paddingLeft: '8px' }} onClick={() => { viewMapping(row.values, row.original.def) }}><Icon color="info">info</Icon></IconButton> } },
        { Header: "Uploaded On", accessor: "createdAt", disableFilters: true, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value ? moment(value).format("MMM DD YYYY HH:mm:ss") : ""}</MDTypography> } },
        { Header: "Uploaded By", accessor: "createdByUser__name", dataType: "textbox", Cell: ({ cell: { value } }) => { if (!value) { return <MDTypography variant="caption" color="dark" display="flex" alignItems="center" justifyContent="flex-start" ><MDAvatar name={"Interface"} size="xs" sx={{ mr: .75 }} />Interface</MDTypography> } else { return <MDTypography variant="caption" color="dark" display="flex" alignItems="center" justifyContent="flex-start" ><MDAvatar name={value} size="xs" sx={{ mr: .75 }} />{value}</MDTypography>} }},
        { Header: "Chargeback", disableFilters: true, accessor: "chargeBack", dataType: "textbox", hidden: hideChargeback, Cell: ({ row, cell: { value } }) => {return (!row.values['monthName__name'] && !row.values['yearName__name']) ? "" : <MDTypography sx={{ padding: 0 }}><Tooltip title={value === true ? "Month is locked" : ''}><Icon sx={{ fontSize: 50 }} color={value === true ? 'error' : "success"}>{value === true ? "lock_icon" : "lock_open_icon"}</Icon></Tooltip></MDTypography>} },
    ]

    if (loading && listLoading) return <YASkeleton variant="loading" />

    if (_err && err)
        console.error(_err)

    return (
        <>
            { !loading && !listLoading &&
                <DataTable
                    variant="tile"
                    table={{ columns, rows: rows??[] }}
                    containerMaxHeight={containerHeight}
                    showTotalEntries={true}
                    isSorted={true}
                    newStyle1={true}
                    noEndBorder
                    entriesPerPage={true}
                    canSearch={true}
                    canFilter={canFilter}
                    filtersState={filtersState}
                    onFiltersStateUpdate={handleOnFiltersStateUpdate}
                    serverSideFetching={true}
                    lookUpList={lookUpList}
                />
            }
            {
                openErr && <DataloadErrorsDialog info={ufData} rows={errors} ufDef = {ufDef} onErrDialogClose={handleErrDialogClose} />
            }
            {
                openMf && <DataloadMfDialog info={ufData} onMfDialogClose={handleMfDialogClose} ufDef={ufDef}
            /> 
            }

            </>
    )
}

export default FilteredUploadedFiles;