import { Card, Divider, Icon, Popover, TextField, Autocomplete, Alert, Tooltip } from "@mui/material"
import MDButton from "components/MDButton"
import MDTypography from "components/MDTypography"
import useFetchRequest from "hooks/useFetchRequest"
import { useEffect, useState, } from "react"
import useHandleError from "hooks/useHandleError"
import MDBox from "components/MDBox"
import EmptyState from "components/EmptyState";
import new_item_img from "assets/svg/add_new.svg";
import fetchRequest from "utils/fetchRequest"
import YASkeleton from "components/YASkeleton"
import numeral from "numeral"
import RenderChart from "./RenderChart"
// import CommentsDrawer from "components/CommentsDrawer"
import useYADialog from "components/YADialog/useYADialog"
import colors from "assets/theme/base/colors"
import { useLocation } from "react-router-dom"
import { useAppController } from "context"
import MDInput from "components/MDInput"
import PageHeader from "components/PageHeaderCF"
import CommentsDrawer from "pages/Forecast/CommentsDrawer"

const InputProjectedForecast = () => {
    let [yearFilter, setYearFilter] = useState(null);
    let location = useLocation();
    const [teamIdForMember, setTeamIdForMember] = useState(null);
    const [teamIdForOwner, setTeamIdForOwner] = useState(null);
    // const [teamName, setTeamName] = useState(sessionStorage['Forecast Team']);
    const [allTeams, SetAllTeams] = useState(null);
    const [menuItem, SetMenuItem] = useState("Financial Year");
    const handleError = useHandleError();
    const { state } = location;
    const [controller,] = useAppController();
    const { userInfo } = controller
    const [team, SetTeam] = useState(state?.[0].values[0]);
    const [allYears, setAllYears] = useState(null);
    const [teamForMember, setTeamsforMember] = useState([]);
    const [yearFilterName, setYearFilterName] = useState(sessionStorage['Year']);
    const [ noTeam, setNoTeam ] = useState(false)


    const cloudEngineer = userInfo?.role.includes("cloudEngineer") && !userInfo?.role.includes('admin') && !userInfo?.role.includes('cloudCostOwner') ? true : false
    const cloudCostOwnerOrAdmin = userInfo?.role.includes("cloudCostOwner") || userInfo?.role.includes('admin') ? true : false

    const { response: res, error: err, loading: load } = cloudEngineer ? useFetchRequest(`/api/forecastInput/categories?memberId=${userInfo.sub}`) : cloudCostOwnerOrAdmin ?
        useFetchRequest(`/api/forecastInput/categories?teamName=${encodeURIComponent(team)}`) : ''

    // sessionStorage.setItem("Forecast Team", String(teamName) && !filterChange ?
    //     ![null, undefined, 'null', 'undefined'].includes(String(teamName)) ?
    //         String(teamName) : userInfo?.role !== 'member' ? res?.allTeams[0]?.name : res?.teamForMember[0]?.name : filterChange.name === 'Forecast Team' ?
    //         filterChange.values : sessionStorage['Forecast Team']);


    useEffect(() => {
        document.body.style.backgroundColor = colors.dashboardBackground;
        if (!res) {
            if (err !== null)
                handleError(err);
        }
        else if (res !== null) {
            setYearFilter(res.years.find(year => year.name === yearFilterName)?.id);
            setTeamIdForMember(res?.teamForMember?.find(mTeam => mTeam.name === team)?.id);
            setTeamIdForOwner(res.teamForOwner?.[0]?.id ? res.teamForOwner?.[0]?.id : team ? team : res?.allTeams[0]?.id);
            setTeamsforMember(res?.teamForMember)
            SetTeam(![null, undefined, 'null', 'undefined'].includes(sessionStorage['Forecast Team']) ? sessionStorage['Forecast Team'] : team ? team : cloudEngineer ? res?.teamForMember[0]?.name : cloudCostOwnerOrAdmin ? res?.allTeams[0]?.name : '')
            // sessionStorage['Forecast Team'] = filterChange.name === 'Forecast Team' ? filterChange.values : team
            // setTeamName(team ? team : ![null, undefined, 'null', 'undefined'].includes(sessionStorage['Forecast Team']) ? sessionStorage['Forecast Team'] :
            // userInfo?.role === 'member' && res?.teamForMember[0]?.name ? res?.teamForMember[0]?.name : userInfo?.role !== 'member' ? res?.allTeams[0]?.name : undefined);
            SetAllTeams([...res.allTeams])
            setAllYears([...res.years])
            setNoTeam(cloudEngineer && teamForMember.length <= 0 ? true : false)
        }
    }, [res, load, team, yearFilter])

    sessionStorage['Forecast Team'] = team
    let pageTitleName = team
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();
    let edit = false;

    const { response, loading, reloadData } = menuItem == "Financial Year" ? useFetchRequest(`/api/forecastInput/forecastMonthly/${yearFilter}?teamName=${team ? encodeURIComponent(team) : undefined}`) :
        useFetchRequest(`/api/forecastInput/forecastRolling/${yearFilter}?teamName=${team ? encodeURIComponent(team) : undefined}`)

    const indexForCurrentMonth = menuItem == "Financial Year" ? response?.findIndex(item => item?.id == currentMonth) : response?.findIndex(item => item?.month == currentMonth)
    if (menuItem == "Financial Year") {
        if (sessionStorage['Year'] < currentYear) {
            response?.map(i => i.edit = false)
        }
        if (sessionStorage['Year'] > currentYear) {
            response?.map(i => i.edit = true)
        }
        if (sessionStorage['Year'] == currentYear) {
            response?.map(i => {
                if (menuItem == "Financial Year") {
                    if (i?.srl == indexForCurrentMonth + 1) {
                        edit = true;
                    }
                    i.edit = edit;
                }
                else {
                    if (i?.id >= 6) {
                        edit = true;
                    }
                    i.edit = edit

                }
            })
        }
    }
    else {
        response?.map(i => {
            if (menuItem == "Financial Year") {
                if (i?.srl == indexForCurrentMonth + 1) {
                    edit = true;
                }
                i.edit = edit;
            }
            else {
                if (i?.id >= 6) {
                    edit = true;
                }
                i.edit = edit

            }
        })

    }

    const rollingMonthsResponse = menuItem == "Rolling 12 Months" ? response : null;

    if (loading || load) {
        return <YASkeleton variant="dashboard-loading" />;
    }

    const renderAddButton = () => (
        <MDButton
            variant="gradient"
            color="info"
            endIcon={<Icon>arrow_forward</Icon>}
            href="/admin/settings#cloudTeam"
        >
            Configure
        </MDButton>
    )

    if (loading === false && load === false && response === null) {
        return (
            <div>
                {(res?.teamForMember?.length === 0 || res?.teamForMember === null) && res?.isData?.length !== 0 && userInfo.role === 'member' ?
                    <>
                        <PageHeader title={'Team Forecast'} noTeam={true} headerShadow={true} />
                        <Alert sx={{ mt: 1 }} severity="info">No team is assigned to you. Please contact your admin.</Alert>
                        <MDBox display="flex" alignItems="center" justifyContent="center" minHeight="calc(100vh - 300px)">
                            <EmptyState
                                size="large"
                                image={new_item_img}
                                title={"No Team Available for Forcasting"}
                                description={""}
                                actions={userInfo.role === 'admin' ? renderAddButton : ''}
                            />
                        </MDBox>
                    </> :
                    <MDBox p={3} pt={10} sx={{ height: '100%' }}>
                        <MDBox display="flex" alignItems="center" justifyContent="center" minHeight="calc(100vh - 300px)">
                            <EmptyState
                                size="large"
                                image={new_item_img}
                                title={"No Team Available for Forcasting"}
                                description={""}
                                actions={userInfo.role.includes('admin') ? renderAddButton : ''}
                            />
                        </MDBox>
                    </MDBox>
                }
            </div>
        );
    }

    return (
        <>
            {location && location.pathname === "/team-forecast" ?
                <PageHeader title={'Team Forecast'} noTeam={cloudEngineer && teamForMember.length <= 0 ? true : false} subtitle={'Forecasting for the Team'} avatar={true} avatarCont={pageTitleName} headerShadow={true} /> :
                <PageHeader title={'Team Forecast'} noTeam={cloudEngineer && teamForMember.length <= 0 ? true : false} subtitle={`Forecasting for the Team`} avatar={true} avatarCont={pageTitleName} drillDownLink={'/forecast/forecasting-overview-admin'} drillDownTitle={'Overview'} headerShadow={true} />
            }
            {cloudEngineer && teamForMember.length <= 0 && <Alert sx={{mt: 1}}severity="info">No team is assigned to you. Please contact your admin.</Alert>}
            {!noTeam && 
            <> 
            <Card sx={{ width: '260px', height: "40px", margin: "20px 0px 0px 40px" }} display="flex" justifyContent="flex-end">
                <Autocomplete
                    disableClearable={true}
                    value={cloudCostOwnerOrAdmin ? teamIdForOwner ? teamIdForOwner : team : cloudEngineer ? teamIdForMember : ''}
                    options={cloudCostOwnerOrAdmin ? allTeams : cloudEngineer ? teamForMember : ''}
                    onChange={(event, newValue) => {
                        SetTeam(newValue.name);
                        if (typeof (Storage) !== "undefined")
                            sessionStorage["Forecast Team"] = newValue.name
                    }}
                    color="text"
                    fontWeight="medium"
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            height: 40,
                            minWidth: 100,
                            maxHeight: 140
                        },
                        "& .MuiAutocomplete-endAdornment": {
                            top: '12px'
                        },
                        "& .MuiOutlinedInput-input": {
                            fontSize: 14,
                            fontWeight: 600
                        },
                        "& .MuiOutlinedInput-input, .MuiAutocomplete-input": {
                        }
                    }}
                    isOptionEqualToValue={(option, value) => {
                        return option.id === value
                    }}
                    getOptionLabel={option => {
                        if (typeof option === "number")
                            return  cloudCostOwnerOrAdmin ? `${("Team: ")}${allTeams?.find(op => op.id === option)?.name}` : cloudEngineer ? "Team: " + teamForMember?.find(op => op.id === option)?.name : '';
                        return option?.name ? option?.name : ''
                    }}

                    renderInput={(params) => <MDInput sx={(theme) => chipStyles(theme)} label="" {...params}
                    inputProps={{
                      ...params.inputProps,
                      'aria-label': 'Teams',
                    }} />} />
            </Card>
            <Card sx={cardStyles} >
                <MDBox pl={2.5} pt={2} display="flex" justifyContent="space-between">
                    <MDBox display="flex" justifyContent="flex-start" >
                        <MDButton sx={(theme) => tabStyles(theme, { selected: menuItem === "Financial Year" })} onClick={() => { SetMenuItem("Financial Year"); reloadData() }} >Financial Year</MDButton>
                        <MDButton disabled={sessionStorage["Forecast Team"] === 'Unknown' ? true : false} sx={(theme) => tabStyles(theme, { selected: menuItem === "Rolling 12 Months" })} onClick={() => { SetMenuItem("Rolling 12 Months"); reloadData() }}>Rolling 12 Months</MDButton>
                    </MDBox>
                </MDBox>

                <Divider style={{ background: '#adadad', marginBottom: '10px', height: "2px" }} variant="middle" omponent="li" />

                <MDBox display="flex" marginRight="15px" justifyContent="flex-start">
                    {menuItem == 'Financial Year' && <MDBox px={0.5} sx={{ margin: "10px 0px 20px 20px" }} display="flex" justifyContent="flex-start"><Card sx={{ minWidth: '125px', maxWidth: '150px' }} >
                        <Autocomplete
                            disableClearable={true}
                            value={yearFilter || null}
                            options={allYears}
                            onChange={(event, newValue) => {
                                setYearFilter(newValue.id); setYearFilterName(newValue.name);
                                if (typeof (Storage) !== "undefined")
                                    sessionStorage["Year"] = newValue.name
                            }}
                            color="text"
                            fontWeight="medium"
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    height: 40,
                                    minWidth: 100,
                                    maxHeight: 140,
                                    align: 'center'
                                },
                                "& .MuiAutocomplete-endAdornment": {
                                    top: '12px'
                                },
                                "& .MuiOutlinedInput-input": {
                                    fontSize: 14,
                                    fontWeight: 600,
                                },
                                "& .MuiOutlinedInput-input, .MuiAutocomplete-input": {
                                }
                            }}
                            isOptionEqualToValue={(option, value) => {
                                return option.id === value
                            }}
                            getOptionLabel={option => {
                                if (typeof option === "number")
                                    return allYears?.find(op => op.id === option)?.name;
                                return option.name
                            }}

                            renderInput={(params) => <> <MDInput sx={(theme) => chipStyles(theme)} label="" {...params}
                            inputProps={{
                              ...params.inputProps,
                              'aria-label': 'Years',
                            }} /></>}
                        /> </Card></MDBox>}

                </MDBox>

                {(cloudCostOwnerOrAdmin && team === 'Unknown') || allTeams.length === 0 || (cloudEngineer && teamForMember.length === 0)  ? <Alert sx={{marginLeft: "20px",marginRight: "20px"}} severity="warning">Forecasting cannot be made</Alert> :
                    <MDBox sx={{ marginLeft: "20px", marginTop: "10px" }}>
                        {
                            response?.map(item => {
                                if (item["forecastedAmount"])
                                    return (<Month key={item["id"]} yearFilter={yearFilter} yearFilterName={yearFilterName} item={item} reloadData={reloadData} teamIdForMember={teamIdForMember} teamIdForOwner={teamIdForOwner} menuItem={menuItem} cloudCostOwnerOrAdmin = {cloudCostOwnerOrAdmin} cloudEngineer = {cloudEngineer} />)
                                else
                                    return (
                                        <MonthEmpty key={item["id"]} item={item} yearFilter={yearFilter} yearFilterName={yearFilterName} reloadData={reloadData} teamIdForMember={teamIdForMember} teamIdForOwner={teamIdForOwner} menuItem={menuItem} cloudCostOwnerOrAdmin = {cloudCostOwnerOrAdmin} cloudEngineer = {cloudEngineer} />
                                    )
                            })
                        }
                    </MDBox>}

                <MDBox>
                    <RenderChart teamFilter={team} yearFilterName={yearFilterName} reloadData={reloadData} menuItem={menuItem} rollingMonthsResponse={rollingMonthsResponse} />
                </MDBox>
            </Card>
            </>
            }
        </>
    )

}
const MonthEmpty = (props) => {

    const { item, yearFilter, reloadData, teamIdForMember, teamIdForOwner, yearFilterName, menuItem, cloudCostOwnerOrAdmin, cloudEngineer } = props
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    // const [controller,] = useAppController();
    // const { userInfo } = controller
    const [monthlyAmount, setMonthlyAmount] = useState('')
    const [comment, setComment] = useState('')
    const { showAlert, showSnackbar } = useYADialog();
    const [amountError, setAmountError] = useState('');
    const [commentError, setCommentError] = useState('');
    const [commentTypePkId, setCommentTypePkId] = useState(null);
    const year = item?.["year"] ? item["year"] : yearFilter;
    const month = item?.["month"] ? item["month"] : item["id"];
    const yearName = item?.["yearName"] ? item["yearName"] : yearFilterName
    // const commentLinkId = userInfo?.role == 'member' ? month + yearName + teamIdForMember : month + yearName + teamIdForOwner

    const handleSave = async (month) => {
        if (monthlyAmount.trim() === '') {
            setAmountError('Amount cannot be empty*')
        }
        if (monthlyAmount.trim() === '' || !monthlyAmount.match(/^[+]?([0-9]+([0-9]*)?|[0-9]+)$/) || Number(monthlyAmount) < 0) {
            setAmountError('Enter Valid Amount*')
        }
        else if (comment.trim() === '') {
            setCommentError('Comment is required*')
        }
        else {
            setAmountError('')
            setCommentError('')
            const [error, data] = await fetchRequest.post(`/api/forecastInput/forecastMonthly/${year}/${month}`, { teamIdForMember: cloudEngineer ? teamIdForMember : null, teamIdForOwner: cloudCostOwnerOrAdmin ? teamIdForOwner : null, amount: monthlyAmount, commentText: comment, commentType: "forecast-item", yearFilterName: yearName, parentCommentId: null });
            if (data && data.result === true) {
                setAnchorEl(null);
                showSnackbar(data.message, "success");
                reloadData();
            }
            else {
                console.error(error);
                showAlert("Save", "Something went wrong. Contact your administrator.");
            }
        }
    }
    const handleCancel = async () => {
        setAnchorEl(null);
    }
    const handleSaveAndCarry = async (month) => {
        if (monthlyAmount.trim() === '') {
            setAmountError('Amount cannot be empty*')
        }
        if (monthlyAmount.trim() === '' || !monthlyAmount.match(/^[+]?([0-9]+([0-9]*)?|[0-9]+)$/) || Number(monthlyAmount) < 0) {
            setAmountError('Enter Valid Amount*')
        }
        else if (comment.trim() === '') {
            setCommentError('Comment is required*')
        }
        else {
            setAmountError('')
            setCommentError('')
            const [error, data] = await fetchRequest.post(`/api/forecastInput/forecastMonthly/${year}/${month}`, { teamIdForMember: cloudEngineer ? teamIdForMember : null, teamIdForOwner: cloudCostOwnerOrAdmin ? teamIdForOwner : null, amount: monthlyAmount, commentText: comment, yearFilterName: yearName, commentType: "forecast-item", parentCommentId: null, saveAndCarryForward: true, menuItem: menuItem });
            if (data && data.result === true) {
                setAnchorEl(null);
                showSnackbar(data.message, "success");
                reloadData();
            }
            else {
                console.error(error);
                showAlert("Save and Carry ", "Something went wrong. Contact your administrator.");
            }
        }
    }
    // const handleCommentWithoutForecast = async () => {
    //     if (!commentTypePkId) {
    //         const [error, data] = await fetchRequest.post(`/api/forecastInput/commentWithoutForecast/${year}/${month}`, { teamIdForMember: userInfo?.role === 'member' ? teamIdForMember : null, teamIdForOwner: userInfo?.role !== 'member' ? teamIdForOwner : null, amount: 0, yearFilterName: yearName });
    //         if (data) {
    //             setCommentTypePkId(data?.id)
    //         }
    //         else {
    //             console.error(error);
    //         }
    //     }
    // }
    const handleAddButtonClick = async (event, pkId) => {
        // if (!commentTypePkId) {
        //     setCommentTypePkId(pkId)
        //     handleCommentWithoutForecast()
        // }
        !commentTypePkId ? setCommentTypePkId(pkId) : ""
        setAnchorEl(event.currentTarget);
    }

    return (
        <>
            <MDBox sx={{ display: "inline-block" }}>
                <MDTypography sx={() => monthTypographyStyles(menuItem, item)}>{item["name"]}</MDTypography>
                <Card sx={(theme) => emptyMonthCardStyles(theme)}>
                    <MDButton sx={(theme) => addButtonStyles(theme)} disableRipple variant="text" iconOnly onClick={(event) => { handleAddButtonClick(event, item["pkId"]) }}>
                        <Icon>add</Icon>
                    </MDButton>
                </Card>
            </MDBox>
            <Popover
                anchorEl={anchorEl}
                open={open}
                id={open ? "simple-popover" : undefined}
                onClose={() => {
                    setAnchorEl(null);
                    setAmountError('');
                    setCommentError('');
                }}
                transformOrigin={{
                    horizontal: "center",
                    vertical: "top",
                }}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                BackdropProps={{ invisible: true }}

                sx={{
                    "& .MuiPaper-root": {
                        background: "#ffffff",
                        border: "1px solid #818DFD",
                        boxShadow: "0px 8px 10px rgba(52, 60, 171, 0.1)",
                        borderRadius: "8px",
                        boxSizing: "border-box",
                        padding: "0px"
                    }
                }}
            >
                {
                    ((cloudEngineer && !item["edit"]) || (item["actualAmount"])) &&
                    <>
                        <MDBox p={2} >
                            <MDTypography sx={(theme) => popoverTypographyStyles(theme)}>
                                {item["name"]}</MDTypography>
                        </MDBox>
                        <MDBox mx={2} mb={2} sx={{ display: "flex" }}>
                            <MDTypography sx={{ color: "#797D7F  ", fontSize: "12px", letterSpacing: "0.5px" }}>{"Forecast"}<MDTypography sx={(theme) => amountNumberStyles(theme)}>{numeral(item["forecastedAmount"]).format('$0,0')}</MDTypography></MDTypography>
                            <MDTypography mx={3} ml={4} sx={{ color: "#797D7F  ", fontSize: "12px", letterSpacing: "0.5px" }}>{"Actual"}<MDTypography sx={(theme) => amountNumberStyles(theme)}>{numeral(item["actualAmount"]).format('$0,0')}</MDTypography></MDTypography>
                            <MDTypography mx={3} sx={{ color: "#797D7F  ", fontSize: "12px", letterSpacing: "0.5px" }}>{"Variance"}<MDTypography sx={(theme) => amountNumberStyles(theme)}>{numeral(parseInt(Number(item["actualAmount"])) - Number(item["forecastedAmount"])).format('$0,0')}</MDTypography></MDTypography>
                        </MDBox>
                    </>
                }

                {
                    ((cloudEngineer && item["edit"]) || cloudCostOwnerOrAdmin) &&
                    <>
                        <MDBox>
                            <MDBox m={2} >
                                <MDTypography sx={(theme) => popoverTypographyStyles(theme)}>
                                    {item["name"]}
                                </MDTypography>
                                <TextField value={monthlyAmount} placeholder="Amount" error={Boolean(amountError)} helperText={amountError} onChange={(e) => { setAmountError(''); setMonthlyAmount(e.target.value) }} />
                            </MDBox>
                            <MDBox p={2}>
                                <MDTypography sx={(theme) => popoverTypographyStyles(theme)}>
                                    Comment</MDTypography>
                                <TextField multiline rows={3} maxRows={3} error={Boolean(commentError)} helperText={commentError} fullWidth value={comment} placeholder="Add a comment..." onChange={(e) => { setCommentError(''); setComment(e.target.value) }} />
                            </MDBox>
                        </MDBox>
                        <MDBox sx={{ backgroundColor: "#EDF1FA", borderRadius: "8px" }} p={2} m={2}>
                            <MDButton size='14px' sx={{
                                fontWeight: "500",
                                lineHeight: "20px",
                                color: "#202223",
                                backgroundColor: "#EDF1FA",
                                "&:hover": {
                                    backgroundColor: "#EDF1FA",
                                }
                            }}
                                onClick={() => handleCancel(month)} >
                                Cancel</MDButton>

                            <MDButton disabled={item["actualAmount"] ? true : false} variant='outlined' color='info' size='14px' sx={{
                                fontWeight: "500",
                                lineHeight: "20px",
                                color: "#4A5AED",
                                marginRight: "22px"
                            }}

                                onClick={() => handleSaveAndCarry(month)} >Save and Carry Forward</MDButton>
                            <MDButton variant='gradient' color='info' size='14px' sx={{
                                fontWeight: "500",
                                lineHeight: "20px",
                                color: "#FFFFFF",
                            }}
                                onClick={() => handleSave(month)}>
                                Save</MDButton>
                        </MDBox>
                        <Divider style={{ background: '#adadad', marginBottom: '10px', height: "2px" }} variant="middle" omponent="li" />
                    </>
                }

                <MDTypography mx={2} my={1} sx={(theme) => commentPopopTypographyStyles(theme)}>Comments</MDTypography>
                <MDBox mx={2} mb={2} sx={{ backgroundColor: "#f0f1fc", borderRadius: "10px", pb: "15px" }}>
                    <CommentsDrawer mode={"edit"} commentType="forecast-item" commentTypePkId={commentTypePkId} setCommentTypePkId={setCommentTypePkId} month={month} year={year} teamId={cloudEngineer ? teamIdForMember : cloudCostOwnerOrAdmin ? teamIdForOwner : null} yearFilterName={yearName} />
                </MDBox>
            </Popover>
        </>
    )
}
const Month = (props) => {

    const { item, yearFilter, reloadData, teamIdForMember, teamIdForOwner, yearFilterName, menuItem, cloudCostOwnerOrAdmin, cloudEngineer } = props
    // const [openComments, setOpenComments] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    // const [controller,] = useAppController();
    // const { userInfo } = controller
    const [commentTypePkId, setCommentTypePkId] = useState(null)
    const [monthlyAmount, setMonthlyAmount] = useState('')
    const [comment, setComment] = useState('')
    const { showAlert, showSnackbar } = useYADialog();
    const [openEdit, SetOpenEdit] = useState(false);
    const [amountError, setAmountError] = useState('');
    const [commentError, setCommentError] = useState('');
    const [operator, SetOperator] = useState("+")
    const ops = [{ value: "addition", label: "+" }, { value: "subtraction", label: "-" }]
    const year = item?.["year"] ? item["year"] : yearFilter;
    const month = item?.["month"] ? item["month"] : item["id"];
    const yearName = item?.["yearName"] ? item["yearName"] : yearFilterName
    // const commentLinkId = userInfo?.role == 'member' ? String(month) + String(yearName) + String(teamIdForMember) : String(month) + String(yearName) + String(teamIdForOwner)


    // const handleComment = (commentTypePkId) => {
    //     setCommentTypePkId(commentTypePkId)
    //     setOpenComments(true);
    // };
    const handleOpenEdit = (event, commentTypePkId) => {
        setCommentTypePkId(commentTypePkId)
        setAnchorEl(event.currentTarget);
        // setOpenComments(false);
        // (teamIdForMember && item["edit"] && !item["actualAmount"]) ? SetOpenEdit(true) : (userInfo?.role == "owner" || userInfo?.role == "admin") ? SetOpenEdit(true) : ""
        SetOpenEdit(true);
    };
    const handleSave = async (month, id) => {
        if (monthlyAmount.trim() === '') {
            setAmountError('Amount cannot be empty*')
        }
        if (monthlyAmount.trim() === '' || !monthlyAmount.match(/^[+]?([0-9]+([0-9]*)?|[0-9]+)$/) || Number(monthlyAmount) < 0) {
            setAmountError('Enter Valid Amount*')
        }
        else if (comment.trim() === '') {
            setCommentError('Comment is required*')
        }
        else {
            setAmountError('')
            setCommentError('')
            const [error, data] = await fetchRequest.post(`/api/forecastInput/forecastMonthly/edit/${year}/${month}/${id}`, { teamIdForMember: cloudEngineer ? teamIdForMember : null, teamIdForOwner: cloudCostOwnerOrAdmin ? teamIdForOwner : null, amount: monthlyAmount, commentText: comment, commentType: "forecast-item", parentCommentId: null, operator: operator, yearFilterName: yearName });
            if (data && data.result === true) {
                setAnchorEl(null);
                showSnackbar(data.message, "success");
                reloadData();
            }
            else {
                console.error(error);
                showAlert("Save", "Something went wrong. Contact your administrator.");
            }
        }
    }
    const handleCancel = async () => {
        setAnchorEl(null);
        SetOpenEdit(false)
        // setOpenComments(false)
    }
    const handleSaveAndCarry = async (month, id) => {
        if (monthlyAmount.trim() === '') {
            setAmountError('Amount cannot be empty*')
        }
        if (monthlyAmount.trim() === '' || !monthlyAmount.match(/^[+]?([0-9]+([0-9]*)?|[0-9]+)$/) || Number(monthlyAmount) < 0) {
            setAmountError('Enter Valid Amount*')
        }
        else if (comment.trim() === '') {
            setCommentError('Comment is required*')
        }
        else {
            setAmountError('')
            setCommentError('')
            const [error, data] = await fetchRequest.post(`/api/forecastInput/forecastMonthly/edit/${year}/${month}/${id}`, { teamIdForMember: cloudEngineer ? teamIdForMember : null, teamIdForOwner: cloudCostOwnerOrAdmin ? teamIdForOwner : null, amount: monthlyAmount, commentText: comment, commentType: "forecast-item", parentCommentId: null, saveAndCarryForward: true, operator: operator, yearFilterName: yearName, menuItem: menuItem });
            if (data && data.result === true) {
                setAnchorEl(null);
                showSnackbar(data.message, "success");
                reloadData();
            }
            else {
                console.error(error);
                showAlert("Save and Carry", "Something went wrong. Contact your administrator.");
            }
        }
    }
    const amountStyles = () => ({
        fontSize: "14px",
        fontWeight: "600",
        lineHeight: "30px",
        letterSpacing: "0.5px",
        textTransform: "capitalize",
    })
    const filledMonthCardStyles = () => ({
        minHeight: "8vh",
        minWidth: "15vh",
        marginTop: "5px",
        display: "inline-block",
        overflow: "hidden",
        position: "relative",
        borderRight: "1px solid #E6E6E6",
        borderRadius: "0px",
        cursor: 'pointer',
        "&:hover": openEdit ? { backgroundColor: "#000000" } : { backgroundColor: "#E6E8FD" },
        // cursor: openEdit || (teamIdForMember && (!item["edit"] || item["actualAmount"])) ? 'default' : 'pointer',
        // color: (teamIdForMember && (!item["edit"] || item["actualAmount"])) ? "#7c7c7c" : "#000000",
        // backgroundColor: (teamIdForMember && item["edit"]) ? "#f4f4f4" : "#ffffff",
    })

    return (
        <MDBox sx={{ display: "inline-block" }}>
            <MDTypography sx={() => monthTypographyStyles(menuItem, item)}>
                {item["name"]}
            </MDTypography>
            {((teamIdForMember && (!item["edit"] || item["actualAmount"])) || (cloudCostOwnerOrAdmin && item["actualAmount"])) ?
                <Tooltip arrow={false} placement="top" title={<h5 style={{ fontWeight: "normal" }} >View Forecast</h5>}>
                    <Card sx={(theme) => filledMonthCardStyles(theme)} onClick={(event) => handleOpenEdit(event, item["pkId"])}>
                        <MDTypography display="flex" color="dark" alignItems="center" justifyContent="flex-start" mx={2.3} mt={1.5} >
                            <MDTypography sx={(theme) => amountStyles(theme)} >{numeral(item["forecastedAmount"]).format('$0,0')}</MDTypography>
                        </MDTypography>
                    </Card>
                </Tooltip> :
                <Tooltip arrow={false} placement="top" title={<h5 style={{ fontWeight: "normal" }} >Edit Forecast</h5>}>
                    <Card sx={(theme) => filledMonthCardStyles(theme)} onClick={(event) => handleOpenEdit(event, item["pkId"])}>
                        <MDTypography display="flex" color="dark" alignItems="center" justifyContent="flex-start" mx={2.3} mt={1.5} >
                            <MDTypography sx={(theme) => amountStyles(theme)} >{numeral(item["forecastedAmount"]).format('$0,0')}</MDTypography>
                            {/* <MDButton sx={{
                    fontSize: "17px",
                    color: "#d4d4d4",
                    "&:hover": {
                        color: "#7c7c7c"
                    }
                }}
                    disableRipple
                    variant="text"
                    iconOnly
                    onClick={(event) => { setAnchorEl(event.currentTarget); SetOpenEdit(false), handleComment(item["pkId"]) }}>
                    <Tooltip arrow={false} title={<h5 style={{ fontWeight: "normal" }} >View Activity</h5>}><Icon sx={{ color: "#818DFD" }}  >comment</Icon></Tooltip>
                </MDButton> */}
                        </MDTypography>
                    </Card>
                </Tooltip>}

            {/* {openComments && <Popover
                anchorEl={anchorEl}
                open={open}
                id={open ? "simple-popover" : undefined}
                onClose={() => {
                    setAnchorEl(null);
                    setOpenComments(false)
                }}
                transformOrigin={{
                    horizontal: "center",
                    vertical: "top",
                }}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
                BackdropProps={{ invisible: true }}

                sx={{
                    "& .MuiPaper-root": {
                        background: "#ffffff",
                        border: "1px solid #818DFD",
                        boxShadow: "0px 8px 10px rgba(52, 60, 171, 0.1)",
                        borderRadius: "8px",
                        boxSizing: "border-box"
                    }
                }}>

                <MDBox>
                    <MDTypography m={1} sx={(theme) => commentPopopTypographyStyles(theme)}>{item["name"]}</MDTypography>
                    <CommentsDrawer mode={"edit"} commentType="forecast-item" commentTypePkId={commentTypePkId} />
                </MDBox>
            </Popover>} */}

            {openEdit && <Popover
                anchorEl={anchorEl}
                open={open}
                id={open ? "simple-popover" : undefined}
                onClose={() => {
                    setAnchorEl(null);
                    SetOpenEdit(false);
                    setAmountError('');
                    setCommentError('');
                }}
                transformOrigin={{
                    horizontal: "center",
                    vertical: "top",
                }}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                BackdropProps={{ invisible: true }}

                sx={{
                    "& .MuiPaper-root": {
                        background: "#ffffff",
                        border: "1px solid #818DFD",
                        boxShadow: "0px 8px 10px rgba(52, 60, 171, 0.1)",
                        borderRadius: "8px",
                        boxSizing: "border-box",
                        padding: "0px"
                    }
                }}
            >

                <MDBox>
                    {((teamIdForMember && (!item["edit"] || item["actualAmount"])) || (cloudCostOwnerOrAdmin && item["actualAmount"])) ?
                        <>
                            <MDBox p={2} >
                                <MDTypography sx={(theme) => popoverTypographyStyles(theme)}>
                                    {item["name"]}</MDTypography>
                            </MDBox>
                            <MDBox mx={2} mb={2} sx={{ display: "flex" }}>
                                <MDTypography sx={{ color: "#797D7F  ", fontSize: "12px", letterSpacing: "0.5px" }}>{"Forecast"}<MDTypography sx={(theme) => amountNumberStyles(theme)}>{numeral(item["forecastedAmount"]).format('$0,0')}</MDTypography></MDTypography>
                                <MDTypography mx={3} ml={4} sx={{ color: "#797D7F  ", fontSize: "12px", letterSpacing: "0.5px" }}>{"Actual"}<MDTypography sx={(theme) => amountNumberStyles(theme)}>{numeral(item["actualAmount"]).format('$0,0')}</MDTypography></MDTypography>
                                <MDTypography mx={3} sx={{ color: "#797D7F  ", fontSize: "12px", letterSpacing: "0.5px" }}>{"Variance"}<MDTypography sx={(theme) => amountNumberStyles(theme)}>{numeral(parseInt(Number(item["actualAmount"])) - Number(item["forecastedAmount"])).format('$0,0')}</MDTypography></MDTypography>
                            </MDBox>
                        </>
                        :
                        <>
                            <MDBox p={2} >
                                <MDTypography sx={(theme) => popoverTypographyStyles(theme)}>
                                    {item["name"]}</MDTypography>
                            </MDBox>
                            <MDBox mx={2} mb={2} sx={{ display: "flex" }}>
                                <MDTypography sx={{ color: "#797D7F  ", fontSize: "12px", letterSpacing: "0.5px" }}>{"Forecast"}<MDTypography sx={(theme) => amountNumberStyles(theme)}>{numeral(item["forecastedAmount"]).format('$0,0')}</MDTypography></MDTypography>
                                <MDTypography mx={3} ml={4} sx={{ color: "#797D7F  ", fontSize: "12px", letterSpacing: "0.5px" }}>{"Actual"}<MDTypography sx={(theme) => amountNumberStyles(theme)}>{numeral(item["actualAmount"]).format('$0,0')}</MDTypography></MDTypography>
                                <MDTypography mx={3} sx={{ color: "#797D7F  ", fontSize: "12px", letterSpacing: "0.5px" }}>{"Variance"}<MDTypography sx={(theme) => amountNumberStyles(theme)}>{numeral(parseInt(Number(item["actualAmount"])) - Number(item["forecastedAmount"])).format('$0,0')}</MDTypography></MDTypography>
                            </MDBox>
                            <MDTypography sx={{ width: "max-content" }} px={2}>
                                <TextField value={numeral(item["forecastedAmount"]).format('$0,0')} disabled={true} sx={{ width: "183px", background: '#F4F4F4' }} />
                                <TextField
                                    select
                                    value={operator || 0}
                                    onChange={(event) => {
                                        SetOperator(event.target.value);
                                    }}
                                    sx={{
                                        mx: '6px',
                                        "& .MuiOutlinedInput-root": {
                                            height: 43,
                                            // px: "20px"
                                        },
                                        "& .MuiOutlinedInput-input": {
                                            ml: 1,
                                            fontSize: 20,
                                            fontWeight: 600
                                        },
                                    }}
                                    defaultValue="addition"
                                    SelectProps={{
                                        native: true,
                                    }}
                                >
                                    {ops.map((option) => (
                                        <option
                                            key={option.value}
                                            value={option.label}>
                                            {option.label}
                                        </option>
                                    ))}
                                </TextField>
                                <TextField value={monthlyAmount} sx={{ width: "187px" }} placeholder="Amount" error={Boolean(amountError)} helperText={amountError} onChange={(e) => { setAmountError(''); setMonthlyAmount(e.target.value) }} />
                            </MDTypography>




                            <MDBox p={2} >
                                <MDTypography sx={(theme) => popoverTypographyStyles(theme)}>
                                    Comment*</MDTypography>
                                <TextField multiline rows={2} maxRows={3} error={Boolean(commentError)} helperText={commentError} fullWidth value={comment} placeholder="Add a comment..." onChange={(e) => { setCommentError(''); setComment(e.target.value) }} />
                            </MDBox>
                        </>
                    }
                </MDBox>

                {((teamIdForMember && (!item["edit"] || item["actualAmount"])) || (cloudCostOwnerOrAdmin && item["actualAmount"])) ? ""
                    :
                    <>
                        <MDBox sx={{ backgroundColor: "#EDF1FA", borderRadius: "7px" }} m={2} py={2}>
                            <MDButton size='14px' sx={{
                                fontWeight: "500",
                                lineHeight: "20px",
                                color: "#202223",
                                backgroundColor: "#EDF1FA",
                                "&:hover": {
                                    backgroundColor: "#EDF1FA",
                                }

                            }}
                                onClick={() => handleCancel(item["id"])} >
                                Cancel</MDButton>
                            <MDButton variant='outlined' color='info' size='14px' sx={{
                                fontWeight: "500",
                                lineHeight: "20px",
                                color: "#4A5AED",
                                marginRight: "22px"
                            }}

                                onClick={() => handleSaveAndCarry(month, item["pkId"])} >Save and Carry Forward</MDButton>
                            <MDButton variant='gradient' color='info' size='14px' sx={{
                                fontWeight: "500",
                                lineHeight: "20px",
                                color: "#FFFFFF",
                            }}
                                onClick={() => handleSave(month, item["pkId"])}>
                                Save</MDButton>
                        </MDBox>
                        <Divider style={{ background: '#adadad', marginBottom: '10px', height: "2px" }} variant="middle" omponent="li" />
                    </>}



                <MDTypography mx={2} my={1} sx={(theme) => commentPopopTypographyStyles(theme)}>Comments</MDTypography>
                <MDBox mx={2} mb={2} sx={{ backgroundColor: "#f0f1fc", borderRadius: "10px", pb: "15px" }}>
                    <CommentsDrawer mode={"edit"} commentType="forecast-item" commentTypePkId={commentTypePkId} />
                </MDBox>

            </Popover>}
        </MDBox>
    )
}
const monthTypographyStyles = (menuItem, item) => ({
    fontSize: "11.5px",
    fontWeight: "500",
    lineHeight: "14px",
    marginLeft: "3px",
    letterSpacing: "0.5px",
    textTransform: "capitalize",
    color: (menuItem == "Rolling 12 Months" && item["id"] == 6) ? "#A020F0" : "#000000"
})
const amountNumberStyles = () => ({
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "30px",
    letterSpacing: "0.5px",
    textTransform: "capitalize",
    color: "#000000",

})
const emptyMonthCardStyles = () => ({
    minHeight: "8vh",
    minWidth: "15vh",
    marginTop: "5px",
    display: "inline-block",
    overflow: "hidden",
    position: "relative",
    backgroundColor: "#F4F4F4",
    borderLeft: "0px solid #E6E6E6",
    border: "1px solid #E6E6E6",
    borderRadius: "0px",
    // padding:"5px"
})
const addButtonStyles = () => ({
    fontSize: "30px",
    color: "#d4d4d4",
    marginLeft: "4.5vh",
    marginTop: "1.5vh",
    "&:hover": {
        color: "#7c7c7c"
    }

})
const popoverTypographyStyles = () => ({
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "14px",
    letterSpacing: "0.5px",
    textTransform: "capitalize",
    marginBottom: .6,
    color: "#000000",
})
const commentPopopTypographyStyles = () => ({
    fontSize: "15px",
    fontWeight: "500",
    lineHeight: "14px",
    letterSpacing: "0.5px",
    textTransform: "capitalize",
    color: "#000000",
    textShadow: "0px 2px 50px rgba(54, 56, 64, 0.05)"

})
const cardStyles = {
    minHeight: "80vh",
    minWidth: "10em",
    margin: "40px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    border: "none",
    overflow: "hidden",
}
const tabStyles = (_theme, { selected }) => ({
    background: selected ? "#E6E8FD" : "#ffffff",
    color: "#000000",
    fontWeight: "500",
    lineHeight: "24px",
    opacity: "0.6",
    marginRight: "5px",
    fontSize: "16px",
    textTransform: "capitalize",
    "&:hover": {
        background: "#E6E8FD"
    }
});
const chipStyles = ({ palette: { white } }) => ({
    cursor: 'pointer',
    border: 'none',
    borderRadius: 1,
    display: 'flex',
    flexDirection: 'row',
    minWidth: 0,
    pb: 1,
    pl: 0.5,
    // margin: '.75 0',
    alignItems: 'center',
    justifyContent: 'space-between',
    whiteSpace: 'nowrap',
    fontSize: '15px',
    backgroundColor: white,
    height: 32,
    position: 'relative',
    "&  .css-135f4hd-MuiOutlinedInput-notchedOutline, .css-at8z4h": { border: 'none' },


    "& .MuiTypography-root, & .MuiIcon-root": { color: '#435cc8!important' },
})
// const opsStyles = () => ({
//     // cursor: 'pointer',
//     // border: 'none',
//     // borderRadius: 1,
//     // display: 'flex',
//     // flexDirection: 'row',
//     // minWidth: 0,
//     // pb: 1,
//     // pl: 0.5,
//     // margin: '.75 0',
//     // alignItems: 'center',
//     // justifyContent: 'space-between',
//     // whiteSpace: 'nowrap',
//     // fontSize: '15px',
//     // backgroundColor: white,
//     // height: 32,
//     // position: 'relative',
//     // "&  .css-135f4hd-MuiOutlinedInput-notchedOutline, .css-at8z4h": { border: 'none' },


//     "& .MuiTypography-root, & .MuiIcon-root": { color: '#435cc8!important' },
//     "&  .MuiOutlinedInput-root .MuiAutocomplete-input": {
//         padding: "0px",
//         minWidth: "15px",
//         maxHeight: "17px",
//         margin: "0px"
//     }

// })

export default InputProjectedForecast