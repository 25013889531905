import React, { useMemo, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useTable, usePagination, useRowSelect, useGlobalFilter, useFilters, useAsyncDebounce, useSortBy, useExpanded, useFlexLayout, useResizeColumns } from "react-table";
import { tableStyles } from "./styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";
import DataTableHeadCell from "components/DataTable/DataTableHeadCell";
import DataTableBodyCell from "components/DataTable/DataTableBodyCell";
import { Checkbox, TableCell, Tooltip, MenuItem, CircularProgress, Menu } from "@mui/material";
import { useDashboardItem } from "components/DashboardItem";
import YAScrollbar from "components/YAScrollbar";
import Autocomplete from '@mui/material/Autocomplete';
import { useImmer } from "use-immer";
import moment from "moment";
import FilterChip from "components/FilterChip";
import LookupFilterChip from "components/LookupFilterChip";
import EmptyState from "components/EmptyState";
import FilterSelector from "components/FilterSelector";
import MDButton from "components/MDButton";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef()
    const resolvedRef = ref || defaultRef

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <Checkbox
          ref={resolvedRef}
          role="form"
          color="primary"
          sx={{
            p: 0,
            "& .MuiSvgIcon-root": {
              height: 18,
              width: 18,
              border: "1px solid #c5c9cc"
            }
          }}
          {...rest}
          inputProps={{ 'aria-label': 'Checkbox' }}
        />
      </>
    )
  }
)

const filterTypeLabels = {
  eq: "is equal to",
  ne: "is not equal to",
  contains: "contains",
  notContains: "does not contain",
  startsWith: "starts with",
  endsWith: "ends with",
  set: "is set",
  notSet: "is not set",
  gt: "is greater than",
  gte: "is greater than or equal to",
  lt: "is less than",
  lte: "is less than or equal to",
  between: "is in between",
  notBetween: "is not in between",
  before: "is before",
  after: "is after",
};

const filterTypes = {
  string: [
    "contains"
  ],
  number: ["eq", "ne", "gt", "gte", "lt", "lte", "between", "notBetween"],
  float: ["eq", "ne", "gt", "gte", "lt", "lte", "between", "notBetween"],
  integer: ["eq", "ne", "gt", "gte", "lt", "lte", "between", "notBetween"],
  currency: ["eq", "ne", "gt", "gte", "lt", "lte", "between", "notBetween"],
  date: [
    "eq",
    "ne",
    "before",
    "after",
    "between",
    "notBetween",
  ],
};

export const getOperatorOptions = (dataType) => {
  return filterTypes[dataType]?.map((f) => (
    <MenuItem key={f} value={f}>
      {filterTypeLabels[f]}
    </MenuItem>
  ));
};

function DefaultColumnFilter({
  column: { accessor, Header, filterValue, preFilteredRows, id, setFilter, onFilterDelete, dataType, serverSideFetching, lookUpList },
}) {
  const [searchVal, setSearchVal] = useState('')
  const [options, setOptions] = useState([])
  useEffect(async () => {
    if (serverSideFetching && lookUpList) {
      const options = new Set()
      lookUpList[id].forEach( option => {
        options.add(option)
      })
      setOptions([...options.values()])
    } else {
      // Calculate the options for filtering
      // using the preFilteredRows
      setOptions(() => {
        const options = new Set()
        preFilteredRows.forEach(row => {
          if ((row.original[id] || "") !== "") options.add(row.original[id])
        })
        return [...options.values()] 
      })
    }
  },[id, preFilteredRows, lookUpList])
  
  const handleOnFilterDelete = () => {
    setFilter()
    if (onFilterDelete)
      onFilterDelete(id)
  }

  const handleOnFilterSave = (filter) => {
    setFilter(filter);
  }

  const handleSearch = (value) => {
    setSearchVal(value)
    return searchVal
  }

  return (
    serverSideFetching ?
    <LookupFilterChip serverSideFetching={serverSideFetching} dataType={dataType || "string"} openOnMount={!filterValue ? true : false} name={accessor} field={Header} filterValue={filterValue} options={options} onSearchVal={handleSearch} searchVal={searchVal} onFilterDelete={handleOnFilterDelete} onFilterSave={handleOnFilterSave} /> :
    <FilterChip dataType={dataType || "string"} openOnMount={!filterValue ? true : false} name={accessor} field={Header} filterValue={filterValue} options={options} onSearchVal={handleSearch} searchVal={searchVal} onFilterDelete={handleOnFilterDelete} onFilterSave={handleOnFilterSave} />
  )
}

const checkFilter = (filter, rowValue) => {
  const fType = filter?.type || 'string';
  const fOperator = filter?.operator || 'eq';
  const fValue = filter?.values || [];
  if (rowValue === undefined || fValue.length === 0)
    return true

  if (['integer', 'float', 'currency'].includes(fType)) {
    if (fOperator === 'ne') {
      return Number(rowValue) !== Number(fValue[0]);
    }
    else if (fOperator === 'gt') {
      return Number(rowValue) > Number(fValue[0]);
    }
    else if (fOperator === 'gte') {
      return Number(rowValue) >= Number(fValue[0]);
    }
    else if (fOperator === 'lt') {
      return Number(rowValue) < Number(fValue[0]);
    }
    else if (fOperator === 'lte') {
      return Number(rowValue) <= Number(fValue[0]);
    }
    else if (fOperator === 'between') {
      return Number(rowValue) >= Number(fValue[0]) && Number(rowValue) <= Number(fValue[1]);
    }
    else if (fOperator === 'notBetween') {
      return Number(rowValue) < Number(fValue[0]) && Number(rowValue) > Number(fValue[1]);
    }
    return Number(rowValue) === Number(fValue[0]);
  }
  else if (fType === 'datepicker') {
    if (fOperator === 'ne') {
      return !moment(rowValue, "YYYY-MM-DD").isSame(moment(fValue[0], "YYYY-MM-DD", true));
    }
    else if (fOperator === 'before') {
      return moment(rowValue, "YYYY-MM-DD").isBefore(moment(fValue[0], "YYYY-MM-DD", true));
    }
    else if (fOperator === 'after') {
      return moment(rowValue, "YYYY-MM-DD").isAfter(moment(fValue[0], "YYYY-MM-DD", true));
    }
    else if (fOperator === 'between') {
      return moment(rowValue, "YYYY-MM-DD").isSameOrAfter(moment(fValue[0], "YYYY-MM-DD", true)) && moment(rowValue, "YYYY-MM-DD").isSameOrBefore(moment(fValue[1], "YYYY-MM-DD", true));
    }
    else if (fOperator === 'notBetween') {
      return moment(rowValue, "YYYY-MM-DD").isBefore(moment(fValue[0], "YYYY-MM-DD", true)) && moment(rowValue, "YYYY-MM-DD").isAfter(moment(fValue[1], "YYYY-MM-DD", true));
    }
    return moment(rowValue, "YYYY-MM-DD").isSame(moment(fValue[0], "YYYY-MM-DD", true));
  }
  else if (fType === 'string') {
    return String(rowValue || "")
      .toLowerCase()
      .includes(String(fValue[0] || "").toLowerCase())
  }

  return fValue.includes(rowValue)
}

function DataTable({
  variant,
  entriesPerPage,
  hideFooterForMinRecords,
  loading,
  canSearch,
  canFilter,
  filtersState,
  onFiltersStateUpdate,
  showTotalEntries,
  table,
  title,
  pagination,
  serverSidePaging,
  pageSizeVal,
  isSorted,
  noEndBorder,
  bordered,
  isSelectable,
  idColumnName,
  onRowClick,
  onUpdate,
  newStyle1,
  deleteMultiple,
  onDeleteMultiple,
  onComareMultiple,
  onOptionsClick,
  onSelectionClearClick,
  containerMaxHeight,
  primaryActions,
  menuActionRender,
  showCellVal,
  primaryRender,
  tabRender,
  filtersComponent,
  renderRowSubComponent,
  expandedRow,
  pgIndx,
  setPgIndx,
  srch,
  setSrch,
  handlePageNav,
  onPageOptionsChange,
  compareMultiple,
  createChargeBack,
  chargeBackAdd,
  approveChargeBack,
  onCreateChargeBack,
  onApproveChargeBack,
  chargeBackApprove,
  setQueryTotal,
  canTotal,
  dynamicTotal,
  vertBorders,
  dismissMultiple,
  onDismissMultiple,
  handlePageOption,
  viewTable,
  serverSideFetching,
  lookUpList,
  clearSelection,

}) {
  const defaultValue = pageSizeVal || 50;
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : [50, 100, 150, 200];
  const columns = useMemo(() => table.columns?.filter(c => !c.hidden), [table]);
  const data = useMemo(() => table.rows, [table]);
  const [openMenu, setOpenMenu] = useState(false);
  const menuActionsArr = (typeof menuActionRender === "function" ? menuActionRender() : menuActionRender) || [];
  const handleOpenMenu = (event) => {
    setOpenMenu(event.currentTarget)
    // showCustomDrawer('', () => <UserInfoDrawer />);
  };
  const handleCloseMenu = () => setOpenMenu(false);

  const handleCloseMenuItem = (a) => {
    setOpenMenu(false)
    if (a.onClick)
      a.onClick();
  };
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
    >
      {
        menuActionsArr?.map(a => (
          <MenuItem key={a.label} onClick={() => handleCloseMenuItem(a)} disabled={a.disabled}>{a.label}</MenuItem>
        ))
      }
    </Menu>
  );

  const [filters, setFilters] = useImmer(filtersState?.filters?.map(f => f.id) || []);

  const handleOnFilterDelete = (selectedFilterName) => {
    setFilters((draft) => draft.filter(f => f !== selectedFilterName));
  };

  const dashboardItemContext = useDashboardItem();

  if (dashboardItemContext && dashboardItemContext.fullscreen)
    containerMaxHeight = "calc(100vh - 224px)";

  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
      filter: 'custom',
      onFilterDelete: handleOnFilterDelete,
      serverSideFetching: serverSideFetching,
      lookUpList: lookUpList,
    }),[lookUpList, serverSideFetching]
  )

  const filterTypes = useMemo(
    () => ({
      custom: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.original[id];
          return checkFilter(filterValue, rowValue);
        });
      },
    }),
    []
  )
  
  const tableInstance = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageIndex: pgIndx && !serverSidePaging ? pgIndx : 0, pageSize: defaultValue || 50, globalFilter: filtersState?.globalFilter, filters: filtersState?.filters || [] },
      autoResetGlobalFilter: false,
      filterTypes,
      autoResetFilters: false,
    },
    useResizeColumns,
    useFlexLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => {

        let newColumns = [];

        if (isSelectable && compareMultiple) {
          newColumns.push(
            // Let's make a column for selection
            {
              id: 'selection',
              maxWidth: 50,
              minWidth: 37,
              // The header can use the table's getToggleAllRowsSelectedProps method
              // to render a checkbox
              Header: ({ getToggleAllPageRowsSelectedProps }) => (
                <div>
                  <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
                </div>
              ),
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a checkbox
              Cell: ({ row }) => row?.original.status !== "CANCELED" && (
                <div>
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                </div>
              ),
              disableSorting: true
            }
          );
        }

        if (isSelectable && dismissMultiple) {
          newColumns.push(
            // Let's make a column for selection
            {
              id: 'selection',
              maxWidth: 50,
              minWidth: 37,
              // The header can use the table's getToggleAllRowsSelectedProps method
              // to render a checkbox
              Header: ({ getToggleAllPageRowsSelectedProps }) => (
                <div>
                  <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
                </div>
              ),
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a checkbox
              Cell: ({ row }) => row?.original.status !== "Dismissed" && (
                <div>
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                </div>
              ),
              disableSorting: true
            }
          );
        }

        if (isSelectable && !compareMultiple && !dismissMultiple && !viewTable) {
          newColumns.push(
            // Let's make a column for selection
            {
              id: 'selection',
              maxWidth: 50,
              minWidth: 37,
              // The header can use the table's getToggleAllRowsSelectedProps method
              // to render a checkbox
              Header: ({ getToggleAllPageRowsSelectedProps }) => (
                <div>
                  <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
                </div>
              ),
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a checkbox
              Cell: ({ row }) => (
                <div>
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                </div>
              ),
              disableSorting: true
            }
          );
        }
        
        if (isSelectable && !compareMultiple && !dismissMultiple && viewTable) {
          newColumns.push(
            // Let's make a column for selection
            {
              id: 'selection',
              maxWidth: 50,
              minWidth: 37,
              // The header can use the table's getToggleAllRowsSelectedProps method
              // to render a checkbox
              Header: ({ getToggleAllPageRowsSelectedProps }) => (
                <div>
                  <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
                </div>
              ),
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a checkbox
              Cell: ({ row }) => row?.original.source === "GL" && (
                <div>
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                </div>
              ),
              disableSorting: true
            }
          );
        }
        // if (isSelectable && !compareMultiple && !dismissMultiple && !viewTable) {
        //   newColumns.push(
        //     // Let's make a column for selection
        //     {
        //       id: 'selection',
        //       maxWidth: 70,
        //       // The header can use the table's getToggleAllRowsSelectedProps method
        //       // to render a checkbox
        //       Header: ({ getToggleAllPageRowsSelectedProps }) => (
        //         <div>
        //           <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
        //         </div>
        //       ),
        //       // The cell can use the individual row's getToggleRowSelectedProps method
        //       // to the render a checkbox
        //       Cell: ({ row }) => row?.original.source === "GL" && (
        //         <div>
        //           <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
        //         </div>
        //       ),
        //       disableSorting: true
        //     }
        //   );
        // }

        return [
          ...newColumns,
          ...columns,
        ]
      })
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    globalFilteredRows,
    setGlobalFilter,
    setAllFilters,
    toggleAllRowsSelected,
    state: { pageIndex, pageSize, selectedRowIds, globalFilter, filters: tableFilters },
  } = tableInstance;

  const prevpage = () => {
    if (setPgIndx && !serverSidePaging) {
      canPreviousPage ? pageIndex !== 0 ? setPgIndx(pageIndex - 1) : setPgIndx(pageIndex) : ''
    }
    previousPage()
  }
  const nextpg = () => {
    if (setPgIndx && !serverSidePaging) {
      canNextPage ? setPgIndx(pageIndex + 1) : ''
    }
    nextPage()
  }

  let filteredRows = globalFilteredRows.map(r => r?.original[idColumnName]);
  let selectedRows = selectedFlatRows.map(r => r?.original[idColumnName]);
  let selectedRows2 = []
  let selectedRows3 = []
  selectedFlatRows.map((r) => {
    if (r?.original['status'] !== "CANCELED")   
      selectedRows2.push(r?.values['name'])
  })
  selectedFlatRows.map((r) => {
    if (r?.original['status'] !== "Dismissed") 
      selectedRows3.push(r?.values['name'])
  })
  useEffect(() => {
    if (isSelectable && onUpdate) onUpdate({ selected: selectedFlatRows.map(r => r?.original[idColumnName]??r[idColumnName]) });
  }, [onUpdate, selectedRowIds]);

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 50), [defaultValue]);

  useEffect(() => {
    if (clearSelection) {
      toggleAllRowsSelected(false); // Uncheck all selected rows
    }
  }, [clearSelection]);


  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value) => {
    setPageSize(value)
    onPageOptionsChange ? onPageOptionsChange({ currentPage: 0, pageSize: parseInt(value) }) : ''
    if (setPgIndx && !serverSidePaging) {
      setPgIndx(null)
    }
  };
  // let sourceName
  
  // rows.filter(row => {
  //   sourceName = row?.original?.source
  // });
  // Render the paginations
  const renderPagination = pageOptions.map((option) => (
    <MDPagination
      item
      size="small"
      key={option}
      onClick={() => gotoPage(Number(option))}
      active={pageIndex === option}
    >
      {option + 1}
    </MDPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));

  // Search input value state
  const [search, setSearch] = useState(globalFilter);
  const [searchDisable, setSearchDisable] = useState (true)

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  useEffect(() => {
    if (onFiltersStateUpdate)
      onFiltersStateUpdate(globalFilter, canFilter ? tableFilters : []);
  }, [globalFilter, tableFilters]);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    // if (isSorted && column.isSorted) {
    if (column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    }
    else if (isSorted) {
      sortedValue = "none";
    }
    else {
      sortedValue = "asce";
    }

    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  if (entriesEnd >= rows.length) {
    entriesEnd = rows.length;
  }

  const handleOnFilterSelect = (selectedFilter) => {
    setFilters((draft) => {
      draft.push(selectedFilter.accessor);
    });
  };

  const handlesearch = (value) => {
    if (setSrch && serverSidePaging) {
      if (value !== "") {
        setSearchDisable(false)
      } else {
        setSearchDisable(true)
        handlePageOption()
        setSrch(value)
      }
    }

    if (setSrch && !serverSidePaging) {
      setSrch(value)
      setPgIndx(null)
    }
  }

  useEffect(() => {
    if (srch) {
      toggleAllRowsSelected(false)
      setSearch(srch);
      onSearchChange(srch);
    }
  }, []);

  useEffect(() => {
    if (handlePageNav && !serverSidePaging)
      handlePageNav(rows)
  }, [rows]);

  if(rows?.length > 0 && canTotal && dynamicTotal) {
    let queryTotal = 0
    rows?.map( row => {
      queryTotal = queryTotal + Number(row?.values[canTotal])
    })
    setQueryTotal(queryTotal)
  }

  if(data?.length > 0 && canTotal && !dynamicTotal) {
    let queryTotal = 0
    data?.map( row => {
      queryTotal = queryTotal + Number(row[canTotal])
    })
    setQueryTotal(queryTotal)
  }
  let handleServersideSearch = () =>{
     setPgIndx(null)
     handlePageOption()
     setSrch(search)
    setSearchDisable(true)
  }

  let headers = [];
  headerGroups.forEach((headerGroup) => headers.push(...headerGroup.headers));

  const toolbarPadding = (variant === "tile" || variant === "subtable") ? { pr: 3, pl: 2.5, py: 1 } : { p: 2.5 }
  const footerPadding = !showTotalEntries && pageOptions.length === 1 ? { p: 0 } : (variant === "tile" || variant === "subtable") ? { px: { lg: 3, md: 3, sm: 0, xs: 0 }, py: 1.5 } : { p: 3 };

  const availableFields = columns?.filter(f => !f.disableFilters && !filters.includes(f.accessor)) || [];
  const filtersApplied = filtersComponent || (search || "") !== "" || filters?.length > 0;

  const chipStyles = ({ palette: { white } }) => ({
    cursor: 'pointer',
    backgroundColor: white,
    "& .MuiOutlinedInput-notchedOutline": { border: 'none' },
  })
  // To get the rows which are not having cloud as source
  let filteredRows1 = rows.filter(row => row.original.source !== "cloud");
  let text = search || filters.length > 0 ? `Delete all searched ${filteredRows1.length} records` : `Delete all ${filteredRows1.length} records`;
  // let text = search || filters.length > 0 ? "Delete all searched  " + rows.length + "  records" : "Delete all  " + rows.length + "  records"
  return (
    <>
      {title ? (
        <MDTypography component="a" sx ={{pr: '70px', pl: '25px', pt: '20px'}} href="#" mr="auto" fontWeight="medium">{title}</MDTypography>
      ): null}
      {canSearch ? (
        <MDBox>
          {tabRender}
          <MDBox display="flex" justifyContent="space-between" alignItems="center"  {...toolbarPadding}>
            <MDBox display="flex" flex={1} justifyContent="flex-start" alignItems="center" flexWrap="wrap">
              {canSearch && !newStyle1 && (
                <MDInput
                  placeholder="Search..."
                  inputProps={{ type: "search" }}
                  value={search || ''}
                  size="small"
                  sx={{ width: "100%", minWidth: "10rem", maxWidth: "20rem", margin: .5 }}
                  onChange={({ currentTarget }) => {
                    toggleAllRowsSelected(false)
                    setSearch(currentTarget.value);
                    onSearchChange(currentTarget.value);
                    handlesearch(currentTarget.value)
                  }}
                />
              )}
              {canSearch && newStyle1 && (
                <>
                  <Icon fontSize="small">search</Icon>
                  <MDInput
                    placeholder="Search..."
                    inputProps={{ type: "search", "aria-label": "Search..." }}
                    value={search}
                    size="small"
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline, &:before": {
                        border: "none"
                      },
                    }}
                    onChange={({ currentTarget }) => {
                      toggleAllRowsSelected(false)
                      setSearch(currentTarget.value);
                      if (!serverSidePaging) {
                        onSearchChange(currentTarget.value);
                      }
                      handlesearch(currentTarget.value)
                    }}
                  />
                  {!searchDisable &&
                    <MDButton
                      size='small'
                      iconOnly
                      variant="gradient"
                      color="success"
                      disabled={searchDisable}
                      sx={{ marginTop: 0, marginLeft: 0.25, height: 30, width: 30 }}
                      onClick={handleServersideSearch}
                    >
                      <Icon>done</Icon>
                    </MDButton>
                  }
                </>
              )}
            {filtersComponent && filtersComponent}
            {canFilter && (
              <>
                {
                  filters?.map((f) => {
                    const column = headers?.find(c => c.id === f);
                    if (column)
                      return column.canFilter ? column.render('Filter') : null;
                    else
                      return null;
                  })
                }
                <FilterSelector fields={availableFields} onFilterSelect={handleOnFilterSelect} />
                {tableFilters?.length > 1 &&
                  <MDButton
                    disableRipple
                    size="large"
                    startIcon={<Icon fontSize="medium">clear</Icon>}
                    sx={{ height: 32, ml: .5, textTransform: 'none', fontSize: 13, p: 1.5 }}
                    variant="text"
                    color="text"
                    onClick={() => {
                      setAllFilters([]);
                      setFilters([]);
                    }}
                  >
                    Clear
                  </MDButton>
                }
              </>
            )}
          </MDBox>
          {(isSelectable && Object.keys(selectedRowIds)?.length > 0) && rows.length > 0 && (
            <>
              {
                deleteMultiple && (
                  <MDButton onClick={() => onDeleteMultiple(selectedRows)} color="error" variant="outlined">
                    {`Delete Selected ${selectedFlatRows.filter(row => row.original.source !== "cloud" || row.original.source === '').length}`}</MDButton>
                )}
              {(!deleteMultiple && !compareMultiple && <MDTypography ml={1} mr={2.5} color="info" variant="button" fontWeight="medium">{`Selected (${Object.keys(selectedRowIds).length})`}</MDTypography>)}

              {
                onSelectionClearClick && (
                  <MDTypography display="inline-flex" component="a" href="#" mr="auto" color="error" variant="button" fontWeight="medium" onClick={onSelectionClearClick}><Icon fontSize="small" color="error">close</Icon>&nbsp;Clear</MDTypography>
                )
              } &nbsp;&nbsp;

              {(compareMultiple && selectedRows2.length < 2 && <MDTypography ml={1} mr={2.5} color="info" variant="button" fontWeight="medium">{`Select another business case for comparison`}</MDTypography>)}
              
              {
                deleteMultiple && pageOptions.length && (
                  <MDButton sx={{ml: 1, mr:2.5}} onClick={() => onDeleteMultiple(filteredRows)} color="error" variant="outlined">{text}</MDButton>
                )}
              {
                compareMultiple && selectedRows2.length >= 2 && (
                  <MDButton onClick={() => onComareMultiple(selectedRows2)} color="info" variant="outlined">{ `Compare Selected ${Object.keys(selectedRowIds).length}`}</MDButton>
                )
              }
              {
                dismissMultiple && (
                  <MDButton onClick={() => onDismissMultiple(selectedRows3)} color="error" variant="outlined" sx={{ mr: 2.5 }}>{ `Dismiss Selected ${Object.keys(selectedRowIds).length}`}</MDButton>
                )
              }
              {/* {
                compareMultiple && pageOptions.length && (
                  <MDButton onClick={() => onComareMultiple(filteredRows)} color="info" variant="outlined">{text}</MDButton>
                )
              } */}
            </>
          )}
          {primaryRender && (primaryRender)}
          {
            createChargeBack && (
              <MDButton disabled={chargeBackAdd} onClick={() => onCreateChargeBack()} color="info" sx={{mr:5}} variant="contained"> { `Create Chareback`}</MDButton>
            )
          }
          {
            approveChargeBack && (
              <MDButton disabled={chargeBackApprove} onClick={() => onApproveChargeBack()} color="info" sx={{mr:5}} variant="contained"> { `Approve Chareback`}</MDButton>
            )
          }
          {onOptionsClick && (
            <MDBox display="flex" color="text" pt={0.3}>
              <Tooltip title="Show / Hide columns">
                <Icon color="text" sx={{ cursor: "pointer", fontWeight: "normal" }} fontSize="medium" onClick={onOptionsClick ? () => onOptionsClick() : undefined}>
                  filter_list
                </Icon>
              </Tooltip>
            </MDBox>
          )}
          {primaryActions && <MDBox ml={1}>
            {primaryActions}
          </MDBox>}
          {
            menuActionsArr && menuActionsArr.length > 0 && (
              <MDBox ml={1}>
                <MDButton
                  // size="medium"
                  disableRipple
                  color="dark"
                  variant="text"
                  onClick={handleOpenMenu}
                  sx={{ "& .MuiIcon-root": { fontSize: "20px!important" } }}
                  iconOnly
                  aria-label="options"
                >
                  <Icon px={0} py={0}>more_horiz</Icon>
                </MDButton>
              </MDBox>
            )
          }
          {renderMenu()}
          {showCellVal && (showCellVal)}
          {/* {title ? (
        <MDTypography display="inline-flex" component="a" sx ={{pr: '70px'}} href="#" mr="auto" fontWeight="medium">{title}</MDTypography>
         ): null} */}
        </MDBox>
        </MDBox>
      ) : null}
      <TableContainer sx={
        containerMaxHeight ? { boxShadow: "none", height: containerMaxHeight } : { boxShadow: "none" }
      }>
        <YAScrollbar disableShadows variant="table">
          {
            loading &&
            <MDBox position="absolute" sx={{ inset: 0, backdropFilter: "blur(1px)" }} display="flex" alignItems="center" justifyContent="center">
              <CircularProgress size={40} sx={{ marginTop: 1 }} color="info" />
            </MDBox>
          }
          {
            rows.length === 0 &&
            <MDBox display="flex" alignItems="center" justifyContent="center" height="100%">
              <EmptyState
                variant="info"
                size="smallMed"
                iconName="find_in_page"
                title={filtersApplied ? "No records were found to match your search" : "No records found"}
                description={filtersApplied ? "Try modifying your search criteria" : ""}
              />
            </MDBox>
          }
          {
            rows.length > 0 &&
            <Table sx={theme => tableStyles(theme, { vertBorders })} {...getTableProps()}>
              <TableHead>
                {headerGroups.map((headerGroup) => (
                  <TableRow key={"TableHeader"} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <DataTableHeadCell
                        key={column.id}
                        {...column.getHeaderProps()}
                        variant={variant}
                        width={column.width ? column.width : "auto"}
                        align={column.align ? column.align : "left"}
                        disableSorting={column.disableSorting}
                        sorted={column.isSorted}
                        sortedBy={setSortedValue(column)}
                        bordered={bordered}
                        isSorted={isSorted}
                        column={column}
                      >
                        {column.render("Header")}
                      </DataTableHeadCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody {...getTableBodyProps()}>
                {page.map((row, key) => {
                  prepareRow(row);
                  return (
                    <>
                      <TableRow
                        {...row.getRowProps()}
                        sx={{
                          "&:hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.04)",
                            cursor: onRowClick ? "pointer" : "default",
                            px: 0,
                            pt: 0,
                            pb: 0,
                          }
                        }}
                        onClick={onRowClick ? (e) => onRowClick(e, row, columns) : undefined}
                      >
                        {row.cells.map((cell) => cell.column.isHeader ? (
                          <DataTableHeadCell
                            key={key}
                            bordered={bordered}
                            disableSorting={true}
                            sorted={false}
                            noBorder={noEndBorder && rows.length - 1 === key}
                            align={cell.column.align ? cell.column.align : "left"}
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </DataTableHeadCell>
                        ) : (
                          <DataTableBodyCell
                            key={key}
                            bordered={bordered}
                            noBorder={noEndBorder && rows.length - 1 === key}
                            align={cell.column.align ? cell.column.align : "left"}
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </DataTableBodyCell>
                        )
                        )}
                      </TableRow>
                      {/*
                    If the row is in an expanded state, render a row with a
                    column that fills the entire length of the table.
                  */}
                      {row.isExpanded && (
                        expandedRow ? expandedRow(row.id) : null,
                        <TableRow>
                          <TableCell
                            colSpan={columns?.length}
                            sx={({ palette: { light }, borders: { borderWidth } }) => ({
                              border: "none",
                              borderBottom: `${borderWidth[1]} solid ${light.main}`,
                              maxWidth: 300,
                              px: 2,
                              pt: 1,
                              pb: 3
                            })}
                          >
                            <YAScrollbar variant="table" disableShadows>
                              {renderRowSubComponent({ row })}
                            </YAScrollbar>
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  );
                })}
              </TableBody>
            </Table>
          }
        </YAScrollbar>
      </TableContainer>
      {!serverSidePaging && <>
        {
          !((hideFooterForMinRecords && rows.length < 50) || rows.length === 0) && !newStyle1 && (
            <MDBox
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              justifyContent="space-between"
              alignItems={{ xs: "flex-start", sm: "center" }}
              {...footerPadding}
            >
              {entriesPerPage && (
                <MDBox display="flex" alignItems="center">
                  <Autocomplete
                    disableClearable
                    value={pageSize}
                    options={entries}
                    onChange={(event, newValue) => {
                      setEntriesPerPage(parseInt(newValue));
                    }}
                    getOptionLabel={option => option?.toString()}
                    size="small"
                    sx={{ width: "5rem" }}
                    renderInput={(params) => <MDInput {...params}
                    inputProps={{
                      ...params.inputProps,
                      'aria-label': 'Rows per page',
                    }} />}
                  />
                  <MDTypography variant="caption" color="secondary">
                    &nbsp;&nbsp;rows per page
                  </MDTypography>
                </MDBox>
              )}
              {showTotalEntries && (
                <MDBox mb={{ xs: 3, sm: 0 }}>
                  <MDTypography variant="button" color="secondary" fontWeight="regular">
                    Showing {entriesStart} to {entriesEnd} of {rows.length}{variant === "page" && " rows"}
                  </MDTypography>
                </MDBox>
              )}
              {pageOptions.length > 1 && (
                <MDPagination
                  variant={pagination.variant ? pagination.variant : "gradient"}
                  color={pagination.color ? pagination.color : "info"}
                >
                  {(variant === "tile" || variant === "subtable") ? (
                    <MDPagination item onClick={() => prevpage()} disabled={!canPreviousPage}>
                      <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
                    </MDPagination>
                  ) : canPreviousPage && (
                      <MDPagination item onClick={() => prevpage()} disabled={!canPreviousPage}>
                      <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
                    </MDPagination>
                  )
                  }
                  {variant === "page" &&
                    (renderPagination.length > 6 ? (
                      <MDBox width="5rem" mx={1} textAlign="center">
                        <MDInput
                          inputProps={{ type: "number", min: 1, max: customizedPageOptions.length, "aria-label": "Page" }}
                          value={customizedPageOptions[pageIndex]}
                          onChange={(handleInputPagination, handleInputPaginationValue)}
                        />
                      </MDBox>
                    ) : (
                      renderPagination
                    )
                    )
                  }
                  {(variant === "tile" || variant === "subtable") ? (
                    <MDPagination item onClick={() => nextpg()} disabled={!canNextPage} extraMargin aria-label="Next Page">
                      <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
                    </MDPagination>
                  ) : canNextPage && (
                    <MDPagination item onClick={() => nextpg()} disabled={!canNextPage} aria-label="Next Page">
                      <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
                    </MDPagination>
                  )
                  }
                </MDPagination>
              )}
            </MDBox>
          )
        }
        {
          !((hideFooterForMinRecords && rows.length < 50) || rows.length === 0) && newStyle1 && (
            <MDBox
              display="flex"
              flexDirection={{ xs: "row", sm: "row" }}
              justifyContent="space-between"
              alignItems={{ xs: "flex-start", sm: "center" }}
              margin={{ lg: 1.3, md: 1.3 }}
              {...footerPadding}
            >
              {pageOptions.length > 1 ? (
                <MDBox display="-webkit-box">
                  <MDPagination variant={""} color="secondary" active>
                    {(variant === "tile" || variant === "subtable") ? (
                      <MDPagination item onClick={() => prevpage()} disabled={!canPreviousPage} active pointerAction="auto"  >
                        <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
                      </MDPagination>
                    ) : canPreviousPage && (
                      <MDPagination item onClick={() => prevpage()} disabled={!canPreviousPage} pointerAction="auto" active>
                        <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
                      </MDPagination>
                    )
                    }
                    {showTotalEntries && (
                      <MDBox >
                        <MDTypography variant="button" color="secondary" width="100%" height="100%" >
                          {pageIndex + 1} of {pageOptions.length}
                        </MDTypography>
                      </MDBox>
                    )}

                    {(variant === "tile" || variant === "subtable") ? (
                      <MDPagination item onClick={() => nextpg()} disabled={!canNextPage} extraMargin active pointerAction="auto" aria-label="Next Page">
                        <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
                      </MDPagination>
                    ) : canNextPage && (
                      <MDPagination item onClick={() => nextpg()} disabled={!canNextPage} pointerAction="auto" active aria-label="Next Page">
                        <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
                      </MDPagination>
                    )
                    }
                  </MDPagination>
                </MDBox>
              ) : <MDBox />}

              {entriesPerPage && (
                <MDBox display="flex" alignItems="center" justifyContent="right">
                  <MDTypography variant="caption" color="secondary">
                    &nbsp;&nbsp;Rows per page:
                  </MDTypography>
                  <Autocomplete
                    disableClearable
                    value={pageSize}
                    options={entries}
                    onChange={(event, newValue) => {
                      setEntriesPerPage(parseInt(newValue));
                    }}
                    getOptionLabel={option => option?.toString()}
                    size="small"
                    sx={{ width: "4rem" }}
                    renderInput={(params) => <MDInput
                      sx={(theme) => chipStyles(theme)}
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        'aria-label': 'Rows per page',
                      }}
                    />}
                  />

                </MDBox>
              )}

            </MDBox>
          )
        }
      </>}
    </>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  variant: "page",
  entriesPerPage: { defaultValue: 50, entries: [50, 100, 150, 200] },
  hideFooterForMinRecords: false,
  loading: false,
  canSearch: false,
  canFilter: false,
  isSelectable: false,
  idColumnName: "id",
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info", pointerAction: "none" },
  isSorted: true,
  noEndBorder: false,
  bordered: false,
  fullscreen: false,
  vertBorders: true
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  variant: PropTypes.oneOf(["page", "tile", "subtable"]),
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  hideFooterForMinRecords: PropTypes.bool,
  loading: PropTypes.bool,
  canSearch: PropTypes.bool,
  canFilter: PropTypes.bool,
  isSelectable: PropTypes.bool,
  idColumnName: PropTypes.string,
  title:PropTypes.string,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  bordered: PropTypes.bool,
  fullscreen: PropTypes.bool,
  vertBorders: PropTypes.bool
};

export default DataTable;
