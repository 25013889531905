import { memo, useEffect, useMemo, useState } from "react";
import MDBox from "components/MDBox";
import { Icon, IconButton, Stack } from "@mui/material";
import EmptyState from "components/EmptyState";
import { chartTypeSettingDefs } from "components/VisualizationRenderer/components/ChartRenderer/constants";
import ConfigSection from "components/ConfigSection";
import CubeFilter from "components/CubeFilter";
import MDTypography from "components/MDTypography";
import OptionsDropdown from "./components/OptionsDropdown";
import DataFieldDropbox from "pages/Analytics1/components/DataFieldDropbox";
import MDButton from "components/MDButton";
import CubeColumnsSelectorDrawer from "../CubeColumnsSelectorDrawer";
import FiltersPanel from "pages/Analytics1/components/FiltersPanel";
import { DataFieldTypes } from "components/DataField";
import DrilldownTab from "../DrilldownTab";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            style={{ height: "100%", width: "100%" }}
        >
            {value === index && (
                <MDBox height="100%" width="100%" {...other}>
                    {children}
                </MDBox>
            )}
        </div>
    );
}

const tabIconStyles = ({ palette: { text, info } }, { selected, rotate }) => ({
    color: selected ? text.main : "#a3a3a3",
    "& .MuiIcon-root": {
        fontSize: "18px!important",
        transform: rotate ? "rotateZ(90deg)" : "none"
    },
    "&::after": selected ? {
        content: '""',
        position: "absolute",
        bottom: -4,
        height: 6,
        width: "90%",
        borderRadius: "6px",
        backgroundColor: info.main
    } : {}
});

const cubeTables = [
    { title: "Spend", type: "Spend", icon: "analytics" },
    { title: "Budget Planning", type: "Budget-planning", icon: "analytics" },
    { title: "Asset TCO", type: "Asset-TCO", icon: "analytics" },
    { title: "Consumption Data", type: "consumption-data", icon: "analytics" },
    { title: "Companion Metrics", type: "companion-metrics", icon: "analytics" },
    { title: "Recommendations", type: "recommendations-data", icon: "analytics" },
    { title: "Business Case", type: "business-case", icon: "analytics" },
    { title: "Forecasts", type: "forecasts", icon: "analytics" },
    { title: "Chargeback", type: "Chargeback", icon: "analytics" },
]

const WidgetConfigPanel = memo(({ chartType, cubeId, globalFilters, onChartTypeChange, onCubeIdChange, visualizationData, onNewFieldAddition, onHover, onDrop, onDelete, onMove, onFieldSort, onFieldSettingChange, onConfigChange, onFilterConditionTypeChange, onFilterDrop, onFilterDelete, onFilterChange, onNewFilterAddition, onDrilldownConfigChange, onClose }) => {
    const [cubeFieldSelectorConfig, setCubeFieldSelectorConfig] = useState({ showSelector: false, dropType: null, filters: [] });

    const isTextWidget = ["text", "header"].includes(chartType);
    const isNonDrilldownWidget = ["text", "header", "table", "pivot-table", "stats", "pie-chart", "donut-chart", "sankey-chart"].includes(chartType);
    const chartConfig = visualizationData.config;

    const chartFilters = visualizationData.filters || [];
    const filterConditionType = visualizationData.filterConditionType || "and";
    const dashboardMode = !!cubeId;

    const [vizConfig, setVizConfig] = useState(chartConfig);
    const [tabValue, setTabValue] = useState(0);
    const chartTypes = useMemo(() => chartTypeSettingDefs.filter(ct => ct.category !== "editor"), []);
    const selectedChartType = chartTypeSettingDefs?.find(c => c.type === chartType);

    useEffect(() => {
        setTabValue((isTextWidget || !dashboardMode) ? 1 : 0);
    }, [chartType, isTextWidget, dashboardMode])

    useEffect(() => {
        setVizConfig(chartConfig);
    }, [chartConfig])

    const handleChange = (newValue) => {
        setTabValue(newValue);
    }

    const handleConfigChange = (configName, value) => {
        if (onConfigChange)
            onConfigChange(configName, value);
    }

    const handleFilterChange = (filterName, filterObj) => {
        if (onFilterChange)
            onFilterChange(filterName, filterObj);
    }

    const handleOnNewFieldAddition = (dropType, filters) => {

        setCubeFieldSelectorConfig({ showSelector: true, dropType, filters })
        // if (onNewFieldAddition)
        //     onNewFieldAddition(cubeId, filter);
    };

    const handleOnNewFieldAdditionCallback = (dropType, field, checked) => {

        setCubeFieldSelectorConfig({ showSelector: false, dropType: null, filters: [] })
        if (dropType === "filters") {
            if (onNewFilterAddition)
                onNewFilterAddition(field);
        }
        else {
            if (onNewFieldAddition)
                onNewFieldAddition(dropType, field, checked);
        }
    };

    const handleOnNewFilterAddition = () => {
        setCubeFieldSelectorConfig({ showSelector: true, dropType: "filters", filters: [DataFieldTypes.DIMENSION, DataFieldTypes.MEASURE] })
        // if (onNewFieldAddition)
        //     onNewFieldAddition(cubeId, filter);
    };

    const handleOnClose = () => {
        if (cubeFieldSelectorConfig.showSelector)
            setCubeFieldSelectorConfig({ showSelector: false, dropType: null, filters: [] })
        else
            onClose();
    };

    const title = cubeFieldSelectorConfig.showSelector ? "Select Fields" : "Customize Widget";

    return (
        <MDBox>
            <MDBox
                position="sticky"
                top={0}
                zIndex={11}
                backgroundColor="#fff!important"
                display="flex"
                px={2.5}
                height={56}
                alignItems="center"
                justifyContent="space-between"
                borderBottom="1px solid #efeaea"
            >
                <MDTypography variant="h5" component="span" fontWeight="medium">{title}</MDTypography>
                <MDButton iconOnly onClick={handleOnClose}>
                    <Icon sx={{ fontSize: "20px!important" }}>close</Icon>
                </MDButton>
            </MDBox>
            {
                cubeFieldSelectorConfig.showSelector &&
                <CubeColumnsSelectorDrawer
                    cubeId={cubeId}
                    dropType={cubeFieldSelectorConfig.dropType}
                    filter={cubeFieldSelectorConfig.filters}
                    onFieldSelectionChange={handleOnNewFieldAdditionCallback}
                />
            }
            {
                !cubeFieldSelectorConfig.showSelector &&
                <MDBox>
                    {
                        (dashboardMode && !isTextWidget) && <MDBox py={2}>
                            <MDTypography px={2} variant="caption" color="text" fontWeight="medium">Analytics Cube</MDTypography>
                            <OptionsDropdown value={cubeId} options={cubeTables} onChange={onCubeIdChange} />
                            <MDTypography px={2} variant="caption" color="text" fontWeight="medium">Chart Type</MDTypography>
                            <OptionsDropdown value={chartType} options={chartTypes} onChange={onChartTypeChange} />
                        </MDBox>
                    }
                    {
                        !isTextWidget && <Stack direction="row" justifyContent="center" spacing={1.5} marginTop={2}>
                            {
                                dashboardMode && <IconButton size="medium" sx={theme => tabIconStyles(theme, { selected: tabValue === 0 })} onClick={() => handleChange(0)}>
                                    <Icon>view_week</Icon>
                                </IconButton>
                            }
                            <IconButton size="medium" sx={theme => tabIconStyles(theme, { selected: tabValue === 1, rotate: true })} onClick={() => handleChange(1)}>
                                <Icon>settings</Icon>
                            </IconButton>
                            <IconButton size="medium" sx={theme => tabIconStyles(theme, { selected: tabValue === 2 })} onClick={() => handleChange(2)}>
                                <Icon>filter_alt</Icon>
                            </IconButton>
                            {
                                dashboardMode && !isNonDrilldownWidget && <IconButton size="medium" sx={theme => tabIconStyles(theme, { selected: tabValue === 3 })} onClick={() => handleChange(3)}>
                                    <Icon>system_update_alt</Icon>
                                </IconButton>
                            }
                        </Stack>
                    }
                    <TabPanel value={tabValue} index={0}>
                        <MDBox py={1.5}>
                            {
                                selectedChartType?.configItems?.map(i => {
                                    const fields = visualizationData[i.type] || [];
                                    return <DataFieldDropbox key={`${chartType}_${i.type}`} dashboardMode={dashboardMode} type={i.type} title={i.title} accept={i.accept} singleItem={i.singleItem || false} fields={fields} onHover={onHover} onDrop={onDrop} onDelete={onDelete} onMove={onMove} onFieldSettingChange={onFieldSettingChange} onFieldSort={onFieldSort} onNewFieldAddition={(filters) => handleOnNewFieldAddition(i.type, filters)} />
                                })
                            }
                        </MDBox>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <MDBox py={1.5}>
                            {
                                selectedChartType?.settingItems?.map(i => {
                                    return <ConfigSection key={`${chartType}_${i.title}`} type={i.type} title={i.title} name={i.name} config={vizConfig} settings={i.settings} onSettingChange={handleConfigChange} />
                                })
                            }
                        </MDBox>
                    </TabPanel>
                    <TabPanel value={tabValue} index={2}>
                        {
                            dashboardMode && <MDBox py={2}>
                                <FiltersPanel dashboardMode={dashboardMode} filters={visualizationData?.filters} filterConditionType={visualizationData?.filterConditionType} onFilterConditionTypeChange={onFilterConditionTypeChange} onFilterDrop={onFilterDrop} onFilterDelete={onFilterDelete} onFilterChange={onFilterChange} onNewFilterAddition={handleOnNewFilterAddition} />
                            </MDBox>
                        }
                        {
                            !dashboardMode && <>
                                {
                                    chartFilters && chartFilters?.length > 0 &&
                                    <MDBox component="ul" py={2} px={1}>
                                        {
                                            chartFilters.map((f, i) =>
                                                <>
                                                    <CubeFilter key={f.name} dismissible={false} filterDef={f} onFilterChange={handleFilterChange} />
                                                    {
                                                        chartFilters.length !== i + 1 &&
                                                        <MDBox key={f.name} component="li" sx={{ listStyle: "none", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                            <MDTypography my={.75} variant="caption" fontWeight="medium">{filterConditionType === "or" ? "Or" : "And"}</MDTypography>
                                                        </MDBox>
                                                    }
                                                </>
                                            )
                                        }
                                    </MDBox>
                                }
                                {
                                    (!chartFilters || chartFilters?.length === 0) &&
                                    <MDBox height="80vh" flex={1} display="flex" alignItems="center" justifyContent="center">
                                        <EmptyState
                                            size="medium"
                                            iconName="filter_alt"
                                            description={"No Filters Found"}
                                            variant="text"
                                        />
                                    </MDBox>
                                }
                            </>
                        }
                    </TabPanel>
                    <TabPanel value={tabValue} index={3}>
                        <DrilldownTab visualizationData={visualizationData} config={visualizationData?.drilldown} globalFilters={globalFilters} onDrilldownConfigChange={onDrilldownConfigChange} />
                    </TabPanel>
                </MDBox>
            }
        </MDBox>
    );
});

export default WidgetConfigPanel;