import { Card, Icon, IconButton, Modal } from "@mui/material";
import moment from "moment";
import DataTable from "components/DataTable";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PerfectScrollbar from 'react-perfect-scrollbar';

const cols = [
    { Header: "Destination", accessor: "destinationColumn", Cell: ({ cell: { value } }) => { return <PerfectScrollbar> <MDTypography variant="caption" color="dark">{value}</MDTypography></PerfectScrollbar> } },
    { Header: "Source", accessor: "sourceColumn", Cell: ({ cell: { value } }) => { return <PerfectScrollbar><MDBox pb={1.5}><MDTypography variant="caption" color="dark">{value}</MDTypography></MDBox></PerfectScrollbar> } },
];

const OtherMappingPairs = ["master : code"]
const excludeFields = ["FOCUS Mapping"]

const DataloadMfDialog = (props) => {
    const { info, onMfDialogClose, ufDef } = props;
    let rows = JSON.parse(info.mappingFields.replace(/'/g, ""));
    if (ufDef) {
        rows = rows.map(row => {
            const match = ufDef.fields.filter(uf => uf.name == row.destinationColumn);
            if (match) {
                row.destinationColumn = match[0]?.displayName || row.destinationColumn;
            }
            OtherMappingPairs.forEach(pair => {
                const [key, value] = pair.split(" : ");
                if (row[key] && !excludeFields.includes(row[key])) {
                    row.destinationColumn = row[key];
                    row.sourceColumn = row[value];
                }
            });
            return row;
        });
    }

    return (
        <Modal open={true} onClose={onMfDialogClose}>
            <MDBox p={3} height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
                <Card sx={{ height: "600px", width: "900px", overflow: 'hidden' }}>
                    <MDBox px={3} pt={2} display="flex" justifyContent="space-between" alignItems="center">
                        <MDBox>
                            <MDTypography variant="h6" component="span" color="text">
                                Data Mapping Fields
                            </MDTypography>
                        </MDBox>
                        <MDBox display="flex">
                            <IconButton onClick={onMfDialogClose} title="Close">
                                <Icon>close</Icon>
                            </IconButton>
                        </MDBox>
                    </MDBox>
                    <MDBox px={3} pt={2} display="flex" lineHeight="0.75" padding='0 auto 0 auto' justifyContent="space-between" alignItems="center">
                        <MDBox flex={1}>
                            <MDTypography fontSize="12px" component="span" color="text">
                                Destination: <b>{info.destinationTable}</b>
                            </MDTypography>
                        </MDBox>
                        <MDBox flex={1} display="flex">
                            <MDTypography fontSize="12px" component="span" color="text">
                                Status:  <b>{info.fileStatus}</b> (<b> {info.loadedRecords}</b> out of <b>{info.totalRecords}</b> )
                            </MDTypography>
                        </MDBox>
                    </MDBox>
                    <MDBox px={3} pt={2} pb={2} display="flex" lineHeight="0.75" padding='0 auto 0 auto' justifyContent="space-between" alignItems="center" borderBottom="1px solid rgba(0, 0, 0, 0.05)">
                        <MDBox flex={1}>
                            <MDTypography fontSize="12px" component="span" color="text">
                                File: <b>{info.originalFileName}</b>
                            </MDTypography>
                        </MDBox>
                        <MDBox flex={1} display="flex">
                            <MDTypography fontSize="12px" component="span" color="text">
                                Upload On:  <b>{moment(info.createdAt).format("MMM DD YYYY hh:mm A")}</b>
                            </MDTypography>
                        </MDBox>
                        <MDBox flex={1} display="flex">
                            <MDTypography fontSize="12px" component="span" color="text">
                            </MDTypography>
                        </MDBox>
                    </MDBox>
                    <DataTable
                        variant="tile"
                        table={{ columns: cols, rows }}
                        containerMaxHeight={392}
                        showTotalEntries={true}
                        isSorted={true}
                        noEndBorder
                        entriesPerPage={true}
                        canSearch={false}
                    >
                    </DataTable>
                </Card>
            </MDBox>
        </Modal>
    );
};

export default DataloadMfDialog;