import DataloadErrorsDialog from "components/DataloadErrorsDialog";
import { useState, useEffect } from "react";
import fetchRequest from "utils/fetchRequest";

export const ErrPopup = ({ fileId, triggerOpen, onClose }) => {
    const [open, setOpen] = useState(Boolean(triggerOpen));
    const [errors, setErrors] = useState(null);
    const [data, setData] = useState(null);
    
    useEffect(() => {
        if (triggerOpen && fileId) {
            setOpen(true);
            const fetchData = async () => {
                try {
                    const [error, result] = await fetchRequest.get(`/api/dataload/uploadstatus/${fileId}`);
                    if (error) {
                        console.error("An error occurred while fetching upload status", error);
                    } else if (result) {
                        setData(result);
                        setErrors(JSON.parse(result.errors.replace(/'/g, "")));
                    }
                } catch (err) {
                    console.error("Fetch error:", err);
                }
            };
            fetchData();
        }
    }, [fileId, triggerOpen]);

    const handleErrDialogClose = () => {
        setOpen(false);
        if (onClose) onClose();
    };

    return (
        <>
            {open && errors && (
                <DataloadErrorsDialog
                    info={data}
                    rows={errors}
                    ufDef={data?.def}
                    onErrDialogClose={handleErrDialogClose}
                />
            )}
        </>
    );
};